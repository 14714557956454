import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DateTime } from 'luxon';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { firstValueFrom } from 'rxjs';
import { PatientScheduleService } from 'src/app/schedule-wise/schedule-patient/services/patient-schedule.service';
import { DivisibleByFiveDirective } from 'src/app/shared/directives/divisibleByFive.directive';
import { isoStringToDateTime } from 'src/app/shared/helpers/dateTime';
import { hourDropdownOptions, minuteDropdownOptions, primeNgOptions } from 'src/app/shared/models/primeNg';

import { IPatientInfoModalResponse, IPatientShiftEvent, IScheduleEvent } from '../../models/patient-modal';
import { PatientsModalService } from '../patients-modal/patients-modal.service';

@Component({
  standalone: true,
  selector: 'app-patients-edit-modal',
  templateUrl: './patients-edit-modal.component.html',
  styleUrls: ['./patients-edit-modal.component.scss'],
  imports: [
    CommonModule,
    DropdownModule,
    FormsModule,
    CheckboxModule,
    FormsModule,
    TableModule,
    ButtonModule,
    InputTextareaModule,
    DropdownModule,
    ConfirmPopupModule,
    CalendarModule,
    InputMaskModule,
    DialogModule,
    InputNumberModule,
    OverlayPanelModule,
    DivisibleByFiveDirective,
    TooltipModule,
  ],
})
export class PatientsEditModalComponent implements OnInit {
  public patientInfo: IPatientInfoModalResponse;
  public hepOptions: primeNgOptions[] = [
    { value: 1, label: 'Unknown' },
    { value: 2, label: 'Immune' },
    { value: 3, label: 'Susceptible' },
    { value: 4, label: 'Positive' },
  ];
  public physicianOptions: primeNgOptions[];
  public transportationOptions: primeNgOptions[];
  public hourOptions = hourDropdownOptions;
  public minuteOptions = minuteDropdownOptions;
  public memoShift: IScheduleEvent;

  private patientId: number;
  private legacyIdClinic: number;

  constructor(
    private patientsModalService: PatientsModalService,
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private messageService: MessageService,
    private patientScheduleService: PatientScheduleService,
  ) {}

  ngOnInit(): void {
    this.patientId = this.config.data.patientId;
    this.legacyIdClinic = this.config.data.legacyIdClinic;
    this.setup();
  }

  public async submit() {
    const promises = [
      firstValueFrom(this.patientsModalService.updatePatient(this.patientInfo)).catch((error) => {
        this.messageService.add({
          summary: 'Failed to update Patient Info',
          detail: 'Something went wrong while modifying the patients info. Please check the data and try again.',
          severity: 'error',
        });
        throw error;
      }),
      firstValueFrom(this.patientsModalService.updatePatientStatus(this.patientInfo)).catch((error) => {
        this.messageService.add({
          summary: 'Failed to update Additional Needs',
          detail: 'Something went wrong while modifying the patients needs. Please check the data and try again.',
          severity: 'error',
        });
        throw error;
      }),
    ];

    this.patientInfo.shifts.forEach((shift) => {
      promises.push(
        firstValueFrom(this.patientsModalService.updatePatientMemo(shift)).catch((error) => {
          this.messageService.add({
            summary: `Failed to update shift ${shift.daysOfTheWeek}`,
            detail: `Something went wrong while modifying the shift ${shift.daysOfTheWeek} info. Please check the data and try again.`,
            severity: 'error',
          });
          throw error;
        }),
      );
      const patientShiftEvent: IPatientShiftEvent = {
        idScheduleEvent: shift.IdScheduleEvent,
        startTime: shift.ontime,
        durationInMinutes: shift.hours * 60 + shift.minutes,
        acuityOnPreMinutes: shift.AcuityOnPre_Minutes,
        acuityOffPreMinutes: shift.AcuityOffPre_Minutes,
        acuityOnPostMinutes: shift.AcuityOnPost_Minutes,
        acuityOffPostMinutes: shift.AcuityOffPost_Minutes,
      };
      promises.push(
        firstValueFrom(this.patientsModalService.updatePatientSchedule(patientShiftEvent)).catch((error) => {
          this.messageService.add({
            summary: `Failed to update shift memo ${shift.daysOfTheWeek}`,
            detail: `Something went wrong while modifying the shift ${shift.daysOfTheWeek} memo. Please check the field and try again.`,
            severity: 'error',
          });
          throw error;
        }),
      );
    });

    await Promise.all(promises);

    this.patientScheduleService.UpdateSchedule();

    this.ref.close(true);
  }

  public close() {
    this.ref.close(false);
  }

  public updateOfftime(shift: IScheduleEvent) {
    shift.offtime = DateTime.fromFormat(shift.ontime, 'HH:mm')
      .plus({ hours: shift.hours, minutes: shift.minutes })
      .toFormat('HH:mm');
  }

  private setup() {
    this.getTransportation();
    this.getPhysician();
    this.getPatient();
  }

  private getPatient() {
    this.patientsModalService.getPatient(this.legacyIdClinic, this.patientId).subscribe({
      next: (patientRes) => {
        this.patientInfo = patientRes;
        this.patientInfo.patient.startDateJs = isoStringToDateTime(this.patientInfo.patient.StartDate).toJSDate();
        this.patientInfo.patient.birthDateJs = isoStringToDateTime(this.patientInfo.patient.DateOfBirth).toJSDate();

        // Delete this so that it does not look filled in the drop down when it is not
        if (this.patientInfo.patient.IdTransportation === 0) {
          delete this.patientInfo.patient.IdTransportation;
        }

        this.patientInfo.shifts = [];
        for (const shiftEvent of this.patientInfo.scheduleEvents) {
          if (this.patientInfo.shifts.some((shift) => shift.IdEventSchedule === shiftEvent.IdEventSchedule)) continue;

          const chair = this.patientInfo.chairs.find((chair) => chair.IdChair === shiftEvent.IdChair);

          const shift = Object.assign({}, shiftEvent, chair);

          if (shiftEvent.IdWeekDay % 2 === 0) {
            shift.daysOfTheWeek = 'T,T,S';
          } else {
            shift.daysOfTheWeek = 'M,W,F';
          }

          const ontime = shift.StartTime.split('T')[1].split(':');

          shift.ontime = `${ontime[0]}:${ontime[1]}`;
          shift.hours = Math.floor(shift.DurationInMinutes / 60);
          shift.minutes = shift.DurationInMinutes % 60;

          this.updateOfftime(shift);

          this.patientInfo.shifts.push(shift);
        }
      },
    });
  }

  private getTransportation() {
    this.patientsModalService.getTransportation(this.legacyIdClinic).subscribe({
      next: (transportations) => {
        this.transportationOptions = transportations.map((transportation) => ({
          value: transportation.intTranspo_Id,
          label: transportation.nvarTranspo_Name,
        }));
      },
    });
  }

  private getPhysician() {
    this.patientsModalService.getPhysician(this.legacyIdClinic).subscribe({
      next: (physicians) => {
        this.physicianOptions = physicians.map((physician) => ({
          value: physician.intPhysician_Id,
          label: `${physician.nvarPhysician_Lname}, ${physician.nvarPhysician_Fname}`,
        }));
      },
    });
  }
}
