import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogConfig, DynamicDialogModule } from 'primeng/dynamicdialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { firstValueFrom, Subscription } from 'rxjs';
import { ClinicService } from 'src/app/shared/services/clinic.service';
import { OverwritePrimeNgService } from 'src/app/shared/services/primeNg.service';
import { minutesToHours } from 'src/app/virtual-lobby/pipe/minutes-to-hours.pipe';

import { IPatientsModalResponse } from '../../models/patient-modal';
import { PatientsEditModalComponent } from '../patients-edit-modal/patients-edit-modal.component';
import { PatientsModalService } from './patients-modal.service';

@Component({
  standalone: true,
  selector: 'app-patients-modal',
  templateUrl: './patients-modal.component.html',
  styleUrls: ['./patients-modal.component.scss'],
  providers: [DialogService, ConfirmationService],
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    ButtonModule,
    DynamicDialogModule,
    MultiSelectModule,
    DropdownModule,
    ConfirmPopupModule,
  ],
})
export class PatientsModalComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[];
  private clinicId: number;
  private legacyIdClinic: number;

  public patients: IPatientsModalResponse[];
  public transportationOptions: { value: string; label: string }[] = [];
  public loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private clinicService: ClinicService,
    private patientsModalService: PatientsModalService,
    private overwritePrimeNgService: OverwritePrimeNgService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private config: DynamicDialogConfig,
  ) {}

  ngOnDestroy(): void {
    this.overwritePrimeNgService.resetPrimeNGTableColumnFilter();
    this.unsubscribe.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit(): void {
    this.overwritePrimeNgService.overridePrimeNGTableColumnFilter();

    this.unsubscribe = [
      this.route.paramMap.subscribe({
        next: async (params) => {
          if (params.get('clinicId')) {
            this.clinicId = +params.get('clinicId');
            this.legacyIdClinic = (
              await firstValueFrom(this.clinicService.getClinicInfo({ clinicId: this.clinicId }))
            ).legacyIdCenter;

            this.getPatients();
          }
        },
      }),
    ];

    if (this.config?.data?.clinicId) {
      this.clinicId = this.config.data.clinicId;
      this.clinicService.getClinicInfo({ clinicId: this.clinicId }).subscribe((clinic) => {
        this.legacyIdClinic = clinic.legacyIdCenter;
        this.getPatients();
      });
    }
  }

  public editPatient(patient: IPatientsModalResponse) {
    this.dialogService
      .open(PatientsEditModalComponent, {
        header: 'Add Treatment',
        data: {
          patientId: patient.IdPatient,
          legacyIdClinic: this.legacyIdClinic,
        },
      })
      .onClose.subscribe({
        next: (update: boolean) => {
          if (update) this.getPatients();
        },
      });
  }

  public deletePatient(event: Event, patient: IPatientsModalResponse) {
    this.confirmationService.confirm({
      target: event.target,
      message: `Are you sure you want to delete ${patient.displayName}?`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: async () => {
        await firstValueFrom(this.patientsModalService.deletePatient(this.legacyIdClinic, patient.IdPatient));
        this.messageService.add({ severity: 'success', detail: 'Patient deleted' });
        this.getPatients();
      },
    });
  }

  public downloadCsv() {
    this.patientsModalService.downloadPatientsCSV(this.legacyIdClinic);
  }

  private getPatients() {
    this.patientsModalService.getPatients(this.legacyIdClinic).subscribe({
      next: (patients) => {
        const transportationOptionsSet = new Set<string>();

        this.patients = patients.map((patient) => {
          // show the name in multiple formats. This allows the search filter to catch any variation of first and last name usage
          // This is ONLY used for filtering not display purposes
          patient.nameFilter = `
          ${patient.PatientLastName}, ${patient.PatientFirstName} ${patient.PatientMiddleName || ''}
          ${patient.PatientLastName} ${patient.PatientFirstName} ${patient.PatientMiddleName || ''}
          ${patient.PatientFirstName} ${
            // This handles the case where there is no middle name
            // It puts a space after the middle name only when there is a middle name
            patient.PatientMiddleName ? patient.PatientMiddleName + ' ' : ''
          }${patient.PatientLastName}
          ${patient.PatientFirstName} ${patient.PatientLastName}
          `;

          patient.displayName = `${patient.PatientLastName}, ${patient.PatientFirstName} ${
            patient.PatientMiddleName || ''
          }`;

          if (patient.provider) {
            patient.providerName = `${patient?.provider?.LastName}, ${patient?.provider?.FirstName} ${
              patient?.provider?.MiddleName || ''
            }`;

            patient.providerNameFilter = `
              ${patient?.provider?.LastName}, ${patient?.provider?.FirstName} ${patient?.provider?.MiddleName || ''}
              ${patient?.provider?.LastName} ${patient?.provider?.FirstName} ${patient?.provider?.MiddleName || ''}
              ${patient?.provider?.FirstName} ${
              // same as above really weird
              patient?.provider?.MiddleName ? patient?.provider?.MiddleName + ' ' : ''
            }${patient?.provider?.LastName}
              ${patient?.provider?.FirstName} ${patient?.provider?.LastName}
            `;
          }

          patient.durationInHours = minutesToHours(patient.DurationInMinutes);

          if (patient?.transportation?.nvarTranspo_Name)
            transportationOptionsSet.add(patient.transportation.nvarTranspo_Name);

          return patient;
        });

        this.transportationOptions = [];
        transportationOptionsSet.forEach((transportation) => {
          this.transportationOptions.push({ label: transportation, value: transportation });
        });
      },
    });
  }
}
