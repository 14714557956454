import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';

import { LocalIndexComponent } from './index/local-index.component';
import { LoginComponent } from './login/login.component';
import { NonSSORoutingModule } from './non-sso-routing.module';
import { PasswordsComponent } from './passwords/passwords.component';
import { SsoRedirectComponent } from './sso-redirect/sso-redirect.component';

@NgModule({
  declarations: [LoginComponent, LocalIndexComponent, SsoRedirectComponent, PasswordsComponent],
  imports: [
    CommonModule,
    InputTextModule,
    RouterModule,
    FormsModule,
    ProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NonSSORoutingModule,
    ButtonModule,
    TableModule,
    DropdownModule,
    MultiSelectModule,
  ],
})
export class NonSSOModule {}
