export enum FrequencyInterval {
  Unused,
  Sun,
  Mon,
  SunMon,
  Tue,
  SunTue,
  MonTue,
  SunMonTue,
  Wed,
  SunWed,
  MonWed,
  SunMonWed,
  TueWed,
  SunTueWed,
  MonTueWed,
  SunMonTueWed,
  Thu,
  SunThu,
  MonThu,
  SunMonThu,
  TueThu,
  SunTueThu,
  MonTueThu,
  SunMonTueThu,
  WedThu,
  SunWedThu,
  MonWedThu,
  SunMonWedThu,
  TueWedThu,
  SunTueWedThu,
  MonTueWedThu,
  SunMonTueWedThu,
  Fri,
  SunFri,
  MonFri,
  SunMonFri,
  TueFri,
  SunTueFri,
  MonTueFri,
  SunMonTueFri,
  WedFri,
  SunWedFri,
  MonWedFri,
  SunMonWedFri,
  TueWedFri,
  SunTueWedFri,
  MonTueWedFri,
  SunMonTueWedFri,
  ThuFri,
  SunThuFri,
  MonThuFri,
  SunMonThuFri,
  TueThuFri,
  SunTueThuFri,
  MonTueThuFri,
  SunMonTueThuFri,
  WedThuFri,
  SunWedThuFri,
  MonWedThuFri,
  SunMonWedThuFri,
  TueWedThuFri,
  SunTueWedThuFri,
  MonTueWedThuFri,
  SunMonTueWedThuFri,
  Sat,
  SunSat,
  MonSat,
  SunMonSat,
  TueSat,
  SunTueSat,
  MonTueSat,
  SunMonTueSat,
  WedSat,
  SunWedSat,
  MonWedSat,
  SunMonWedSat,
  TueWedSat,
  SunTueWedSat,
  MonTueWedSat,
  SunMonTueWedSat,
  ThuSat,
  SunThuSat,
  MonThuSat,
  SunMonThuSat,
  TueThuSat,
  SunTueThuSat,
  MonTueThuSat,
  SunMonTueThuSat,
  WedThuSat,
  SunWedThuSat,
  MonWedThuSat,
  SunMonWedThuSat,
  TueWedThuSat,
  SunTueWedThuSat,
  MonTueWedThuSat,
  SunMonTueWedThuSat,
  FriSat,
  SunFriSat,
  MonFriSat,
  SunMonFriSat,
  TueFriSat,
  SunTueFriSat,
  MonTueFriSat,
  SunMonTueFriSat,
  WedFriSat,
  SunWedFriSat,
  MonWedFriSat,
  SunMonWedFriSat,
  TueWedFriSat,
  SunTueWedFriSat,
  MonTueWedFriSat,
  SunMonTueWedFriSat,
  ThuFriSat,
  SunThuFriSat,
  MonThuFriSat,
  SunMonThuFriSat,
  TueThuFriSat,
  SunTueThuFriSat,
  MonTueThuFriSat,
  SunMonTueThuFriSat,
  WedThuFriSat,
  SunWedThuFriSat,
  MonWedThuFriSat,
  SunMonWedThuFriSat,
  TueWedThuFriSat,
  SunTueWedThuFriSat,
  MonTueWedThuFriSat,
  SunMonTueWedThuFriSat,
}

export const FrequencyIntervalDays = [
  [],
  [1],
  [2],
  [1, 2],
  [3],
  [1, 3],
  [2, 3],
  [1, 2, 3],
  [4],
  [1, 4],
  [2, 4],
  [1, 2, 4],
  [3, 4],
  [1, 3, 4],
  [2, 3, 4],
  [1, 2, 3, 4],
  [5],
  [1, 5],
  [2, 5],
  [1, 2, 5],
  [3, 5],
  [1, 3, 5],
  [2, 3, 5],
  [1, 2, 3, 5],
  [4, 5],
  [1, 4, 5],
  [2, 4, 5],
  [1, 2, 4, 5],
  [3, 4, 5],
  [1, 3, 4, 5],
  [2, 3, 4, 5],
  [1, 2, 3, 4, 5],
  [6],
  [1, 6],
  [2, 6],
  [1, 2, 6],
  [3, 6],
  [1, 3, 6],
  [2, 3, 6],
  [1, 2, 3, 6],
  [4, 6],
  [1, 4, 6],
  [2, 4, 6],
  [1, 2, 4, 6],
  [3, 4, 6],
  [1, 3, 4, 6],
  [2, 3, 4, 6],
  [1, 2, 3, 4, 6],
  [5, 6],
  [1, 5, 6],
  [2, 5, 6],
  [1, 2, 5, 6],
  [3, 5, 6],
  [1, 3, 5, 6],
  [2, 3, 5, 6],
  [1, 2, 3, 5, 6],
  [4, 5, 6],
  [1, 4, 5, 6],
  [2, 4, 5, 6],
  [1, 2, 4, 5, 6],
  [3, 4, 5, 6],
  [1, 3, 4, 5, 6],
  [2, 3, 4, 5, 6],
  [1, 2, 3, 4, 5, 6],
  [7],
  [1, 7],
  [2, 7],
  [1, 2, 7],
  [3, 7],
  [1, 3, 7],
  [2, 3, 7],
  [1, 2, 3, 7],
  [4, 7],
  [1, 4, 7],
  [2, 4, 7],
  [1, 2, 4, 7],
  [3, 4, 7],
  [1, 3, 4, 7],
  [2, 3, 4, 7],
  [1, 2, 3, 4, 7],
  [5, 7],
  [1, 5, 7],
  [2, 5, 7],
  [1, 2, 5, 7],
  [3, 5, 7],
  [1, 3, 5, 7],
  [2, 3, 5, 7],
  [1, 2, 3, 5, 7],
  [4, 5, 7],
  [1, 4, 5, 7],
  [2, 4, 5, 7],
  [1, 2, 4, 5, 7],
  [3, 4, 5, 7],
  [1, 3, 4, 5, 7],
  [2, 3, 4, 5, 7],
  [1, 2, 3, 4, 5, 7],
  [6, 7],
  [1, 6, 7],
  [2, 6, 7],
  [1, 2, 6, 7],
  [3, 6, 7],
  [1, 3, 6, 7],
  [2, 3, 6, 7],
  [1, 2, 3, 6, 7],
  [4, 6, 7],
  [1, 4, 6, 7],
  [2, 4, 6, 7],
  [1, 2, 4, 6, 7],
  [3, 4, 6, 7],
  [1, 3, 4, 6, 7],
  [2, 3, 4, 6, 7],
  [1, 2, 3, 4, 6, 7],
  [5, 6, 7],
  [1, 5, 6, 7],
  [2, 5, 6, 7],
  [1, 2, 5, 6, 7],
  [3, 5, 6, 7],
  [1, 3, 5, 6, 7],
  [2, 3, 5, 6, 7],
  [1, 2, 3, 5, 6, 7],
  [4, 5, 6, 7],
  [1, 4, 5, 6, 7],
  [2, 4, 5, 6, 7],
  [1, 2, 4, 5, 6, 7],
  [3, 4, 5, 6, 7],
  [1, 3, 4, 5, 6, 7],
  [2, 3, 4, 5, 6, 7],
  [1, 2, 3, 4, 5, 6, 7],
];

export const FrequencyIntervalNames = [
  'Nil',
  'S',
  'M',
  'SM',
  'T',
  'ST',
  'MT',
  'SMT',
  'W',
  'SW',
  'MW',
  'SMW',
  'TW',
  'STW',
  'MTW',
  'SMTW',
  'T',
  'ST',
  'MT',
  'SMT',
  'TT',
  'STT',
  'MTT',
  'SMTT',
  'WT',
  'SWT',
  'MWT',
  'SMWT',
  'TWT',
  'STWT',
  'MTWT',
  'SMTWT',
  'F',
  'SF',
  'MF',
  'SMF',
  'TF',
  'STF',
  'MTF',
  'SMTF',
  'WF',
  'SWF',
  'MWF',
  'SMWF',
  'TWF',
  'STWF',
  'MTWF',
  'SMTWF',
  'TF',
  'STF',
  'MTF',
  'SMTF',
  'TTF',
  'STTF',
  'MTTF',
  'SMTTF',
  'WTF',
  'SWTF',
  'MWTF',
  'SMWTF',
  'TWTF',
  'STWTF',
  'MTWTF',
  'SMTWTF',
  'S',
  'SS',
  'MS',
  'SMS',
  'TS',
  'STS',
  'MTS',
  'SMTS',
  'WS',
  'SWS',
  'MWS',
  'SMWS',
  'TWS',
  'STWS',
  'MTWS',
  'SMTWS',
  'TS',
  'STS',
  'MTS',
  'SMTS',
  'TTS',
  'STTS',
  'MTTS',
  'SMTTS',
  'WTS',
  'SWTS',
  'MWTS',
  'SMWTS',
  'TWTS',
  'STWTS',
  'MTWTS',
  'SMTWTS',
  'FS',
  'SFS',
  'MFS',
  'SMFS',
  'TFS',
  'STFS',
  'MTFS',
  'SMTFS',
  'WFS',
  'SWFS',
  'MWFS',
  'SMWFS',
  'TWFS',
  'STWFS',
  'MTWFS',
  'SMTWFS',
  'TFS',
  'STFS',
  'MTFS',
  'SMTFS',
  'TTFS',
  'STTFS',
  'MTTFS',
  'SMTTFS',
  'WTFS',
  'SWTFS',
  'MWTFS',
  'SMWTFS',
  'TWTFS',
  'STWTFS',
  'MTWTFS',
  'SMTWTFS',
];

export enum FullNameFrequencyInterval {
  Unused,
  Sunday,
  Monday,
  SundayMonday,
  Tuesday,
  SundayTuesday,
  MondayTuesday,
  SundayMondayTuesday,
  Wednesday,
  SundayWednesday,
  MondayWednesday,
  SundayMondayWednesday,
  TuesdayWednesday,
  SundayTuesdayWednesday,
  MondayTuesdayWednesday,
  SundayMondayTuesdayWednesday,
  Thursday,
  SundayThursday,
  MondayThursday,
  SundayMondayThursday,
  TuesdayThursday,
  SundayTuesdayThursday,
  MondayTuesdayThursday,
  SundayMondayTuesdayThursday,
  WednesdayThursday,
  SundayWednesdayThursday,
  MondayWednesdayThursday,
  SundayMondayWednesdayThursday,
  TuesdayWednesdayThursday,
  SundayTuesdayWednesdayThursday,
  MondayTuesdayWednesdayThursday,
  SundayMondayTuesdayWednesdayThursday,
  Friday,
  SundayFriday,
  MondayFriday,
  SundayMondayFriday,
  TuesdayFriday,
  SundayTuesdayFriday,
  MondayTuesdayFriday,
  SundayMondayTuesdayFriday,
  WednesdayFriday,
  SundayWednesdayFriday,
  MondayWednesdayFriday,
  SundayMondayWednesdayFriday,
  TuesdayWednesdayFriday,
  SundayTuesdayWednesdayFriday,
  MondayTuesdayWednesdayFriday,
  SundayMondayTuesdayWednesdayFriday,
  ThursdayFriday,
  SundayThursdayFriday,
  MondayThursdayFriday,
  SundayMondayThursdayFriday,
  TuesdayThursdayFriday,
  SundayTuesdayThursdayFriday,
  MondayTuesdayThursdayFriday,
  SundayMondayTuesdayThursdayFriday,
  WednesdayThursdayFriday,
  SundayWednesdayThursdayFriday,
  MondayWednesdayThursdayFriday,
  SundayMondayWednesdayThursdayFriday,
  TuesdayWednesdayThursdayFriday,
  SundayTuesdayWednesdayThursdayFriday,
  MondayTuesdayWednesdayThursdayFriday,
  SundayMondayTuesdayWednesdayThursdayFriday,
  Saturday,
  SundaySaturday,
  MondaySaturday,
  SundayMondaySaturday,
  TuesdaySaturday,
  SundayTuesdaySaturday,
  MondayTuesdaySaturday,
  SundayMondayTuesdaySaturday,
  WednesdaySaturday,
  SundayWednesdaySaturday,
  MondayWednesdaySaturday,
  SundayMondayWednesdaySaturday,
  TuesdayWednesdaySaturday,
  SundayTuesdayWednesdaySaturday,
  MondayTuesdayWednesdaySaturday,
  SundayMondayTuesdayWednesdaySaturday,
  ThursdaySaturday,
  SundayThursdaySaturday,
  MondayThursdaySaturday,
  SundayMondayThursdaySaturday,
  TuesdayThursdaySaturday,
  SundayTuesdayThursdaySaturday,
  MondayTuesdayThursdaySaturday,
  SundayMondayTuesdayThursdaySaturday,
  WednesdayThursdaySaturday,
  SundayWednesdayThursdaySaturday,
  MondayWednesdayThursdaySaturday,
  SundayMondayWEdneWednesdayThursdaySaturday,
  TuesdayWednesdayThursdaySaturday,
  SundayTuesdayWednesdayThursdaySaturday,
  MondayTuesdayWednesdayThursdaySaturday,
  SundayMondayTuesdayWednesdayThursdaySaturday,
  FridaySaturday,
  SundayFridaySaturday,
  MondayFridaySaturday,
  SundayMondayFridaySaturday,
  TuesdayFridaySaturday,
  SundayTuesdayFridaySaturday,
  MondayTuesdayFridaySaturday,
  SundayMondayTuesdayFridaySaturday,
  WednesdayFridaySaturday,
  SundayWednesdayFridaySaturday,
  MondayWednesdayFridaySaturday,
  SundayMondayWednesdayFridaySaturday,
  TuesdayWednesdayFridaySaturday,
  SundayTuesdayWednesdayFridaySaturday,
  MondayTuesdayWednesdayFridaySaturday,
  SundayMondayTuesdayWednesdayFridaySaturday,
  ThursdayFridaySaturday,
  SundayThursdayFridaySaturday,
  MondayThursdayFridaySaturday,
  SundayMondayThursdayFridaySaturday,
  TuesdayThursdayFridaySaturday,
  SundayTuesdayThursdayFridaySaturday,
  MondayTuesdayThursdayFridaySaturday,
  SundayMondayTuesdayThursdayFridaySaturday,
  WednesdayThursdayFridaySaturday,
  SundayWednesdayThursdayFridaySaturday,
  MondayWednesdayThursdayFridaySaturday,
  SundayMondayWednesdayThursdayFridaySaturday,
  TuesdayWednesdayThursdayFridaySaturday,
  SundayTuesdayWednesdayThursdayFridaySaturday,
  MondayTuesdayWednesdayThursdayFridaySaturday,
  SundayMondayTuesdayWednesdayThursdayFridaySaturday,
}
export type FullNameFrequencyIntervalString = keyof typeof FullNameFrequencyInterval;

export const DayNamesFull = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const DayNamesShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const DayLetters = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
export const SingleDayFrequencyInterval = [1, 2, 4, 8, 16, 32, 64];
