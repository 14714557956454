import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TableModule } from 'primeng/table';
import { firstValueFrom, Subscription } from 'rxjs';
import { ClinicService } from 'src/app/shared/services/clinic.service';
import { OverwritePrimeNgService } from 'src/app/shared/services/primeNg.service';
import { SessionService } from 'src/app/shared/services/session.service';

import { IPhysiciansListResponse } from '../../models/patient-modal';
import { PatientsModalService } from '../patients-modal/patients-modal.service';

@Component({
  selector: 'app-physicians-modal',
  templateUrl: './physicians-modal.component.html',
  styleUrls: ['./physicians-modal.component.scss'],
  standalone: true,
  providers: [DialogService, ConfirmationService],
  imports: [CommonModule, FormsModule, ButtonModule, TableModule, ConfirmPopupModule],
})
export class PhysiciansModalComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[];
  private clinicId: number;
  private legacyIdClinic: number;
  public physicians: IPhysiciansListResponse[] = [];
  public addPermission: boolean = false;
  public deletePermission: boolean = false;
  public firstName = '';
  public lastName = '';

  constructor(
    private route: ActivatedRoute,
    private clinicService: ClinicService,
    private patientsModalService: PatientsModalService,
    private overwritePrimeNgService: OverwritePrimeNgService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private sessionService: SessionService,
    private config: DynamicDialogConfig,
  ) {}

  ngOnDestroy(): void {
    this.overwritePrimeNgService.resetPrimeNGTableColumnFilter();
    this.unsubscribe.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit(): void {
    this.addPermission = this.sessionService.checkUserPermissionsAny(['RXADD']);
    this.deletePermission = this.sessionService.checkUserPermissionsAny(['RXDEL']);

    this.overwritePrimeNgService.overridePrimeNGTableColumnFilter();

    this.unsubscribe = [
      this.route.paramMap.subscribe({
        next: async (params) => {
          if (params.get('clinicId')) {
            this.clinicId = +params.get('clinicId');
            this.legacyIdClinic = (
              await firstValueFrom(this.clinicService.getClinicInfo({ clinicId: this.clinicId }))
            ).legacyIdCenter;

            this.getPhysicians();
          }
        },
      }),
    ];

    if (this.config?.data?.clinicId) {
      this.clinicId = this.config.data.clinicId;
      this.clinicService.getClinicInfo({ clinicId: this.clinicId }).subscribe((clinic) => {
        this.legacyIdClinic = clinic.legacyIdCenter;
        this.getPhysicians();
      });
    }
  }

  getPhysicians() {
    this.patientsModalService.getPhysician(this.legacyIdClinic).subscribe((physicians) => {
      this.physicians = physicians;
    });
  }

  confirmDelete(event: Event, physician: IPhysiciansListResponse) {
    this.confirmationService.confirm({
      target: event.target,
      message: `Are you sure you want to delete ${physician.nvarPhysician_Lname}, ${physician.nvarPhysician_Fname}?`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: async () => {
        await firstValueFrom(this.patientsModalService.deletePhysician(this.legacyIdClinic, physician.IdProvider));
        this.messageService.add({
          severity: 'success',
          detail: `Physician ${physician.nvarPhysician_Lname}, ${physician.nvarPhysician_Fname} deleted.`,
        });
        this.getPhysicians();
      },
    });
  }

  addPhysician() {
    this.patientsModalService.addPhysician(this.legacyIdClinic, this.firstName, this.lastName).subscribe(() => {
      this.firstName = '';
      this.lastName = '';
      this.getPhysicians();
    });
  }
}
