import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject, throwError } from 'rxjs';
import { GroupList } from 'src/app/dashboard/shared/area.models';
import { cookies } from 'src/app/shared/interfaces/session';
import { ApiService } from 'src/app/shared/services/api.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { UserService } from 'src/app/shared/services/user.service';

import { ClinicDetail, ClinicInfo } from '../../../../src/app/dashboard/shared/clinic.models';
import {
  IndirectShiftData,
  SelectedShifts,
  ShiftList,
  ShiftModalInfo,
} from '../core/models/schedule-wise-staff.models';
import { IStaffConfigEvents, IUpdateShift } from '../shared/models/shifts';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  private clinicList: ClinicDetail[];
  private groupList: GroupList;
  private regionList: GroupList;
  private clinicListObservable: Observable<ClinicDetail[]>;

  constructor(
    private api: ApiService,
    private cookieService: CookieService,
    private sessionService: SessionService,
    private userService: UserService,
    private apiService: ApiService,
  ) {}

  public getVirtualLobbyCount(centerId: string): Observable<any> {
    return this.apiService.makeRequest<any>('get', `virtual-lobby/count/${centerId}`, { ignoreErrors: true });
  }

  public getClinicList(userId: string): Observable<ClinicDetail[]> {
    if (this.clinicListObservable) {
      return this.clinicListObservable;
    }

    this.clinicListObservable = this.api.makeRequest<ClinicDetail[]>('get', `users/clinics/${userId}`);
    this.clinicListObservable.subscribe((clinicList) => {
      this.clinicList = clinicList;
    });
    return this.clinicListObservable;
  }

  public getClinic(centerCode: string): ClinicDetail | undefined {
    return this.clinicList.find((clinic) => clinic.CenterCode === centerCode);
  }

  public getFirstClinic() {
    return this.clinicList[0];
  }
  public getClinicByLegacyId(idCenter: number): ClinicDetail | undefined {
    return this.clinicList.find((clinic) => +clinic.IdCenter === +idCenter);
  }
  public getClinicByClinicId(clinicId: number): ClinicDetail | undefined {
    return this.clinicList.find((clinic) => +clinic.clinicId === +clinicId);
  }

  public getLastModifiedInformation(centerId: string): Observable<any> {
    return this.api.makeRequest<any>('get', `staff/center/${centerId}/last-updated`);
  }

  public setSelectedClinicOnCFSession(centerId: string): Observable<any> {
    const partnerKey = environment.partnerKey;
    let payload = new HttpParams().set(
      'userSessionId',
      environment.local ? localStorage.getItem('fmcSession') : this.cookieService.get(cookies['swSessionId']),
    );
    payload = payload.append('PARTNERKEY', partnerKey);

    const url = `api/rest/clinicChange.cfm?newCenterId=${centerId}`;
    return this.api.makeColdFusionRequest<any>('post', url, { payload });
  }

  public getStaffList(centerId: string, startDate: string, endDate?: string): Observable<any> {
    let queryParams = new HttpParams().append('startDate', startDate.toString());
    if (endDate) queryParams = queryParams.append('endDate', endDate.toString());
    return this.api.makeRequest<any>('get', `staff/center/${centerId}`, { queryParams });
  }

  public getShiftList(
    centerId: string,
    startDate: string,
    endDate?: string,
    getStaffHours?: boolean,
  ): Observable<ShiftList> {
    let queryParams = new HttpParams().append('startDate', startDate.toString());
    if (endDate) queryParams = queryParams.append('endDate', endDate.toString());
    queryParams = queryParams.append('getStaffHours', String(getStaffHours));
    return this.api.makeRequest<any>('get', `staff/shifts/center/${centerId}`, { queryParams });
  }

  public getIndirectShifts(centerId: string): Observable<IndirectShiftData[]> {
    return this.api.makeRequest<IndirectShiftData[]>('get', `staff/indirect-shifts/center/${centerId}`);
  }

  public saveIndirectShifts(centerId: string, shifts: IndirectShiftData[]): Observable<IndirectShiftData[]> {
    return this.api.makeRequest<IndirectShiftData[]>('post', `staff/indirect-shifts/center/${centerId}`, {
      payload: shifts,
    });
  }

  public getShiftTypes(): Observable<any> {
    return this.api.makeRequest<any>('get', `staff/shift/type`);
  }

  public getStaffTypes(): Observable<any> {
    return this.api.makeRequest<any>('get', `staff/staff-type`);
  }

  public getPodsByClinic(centerId: string): Observable<any> {
    return this.api.makeRequest<any>('get', `staff/pods/center/${centerId}`);
  }

  public getClinicInfo(centerId: string): Observable<ClinicInfo> {
    return this.api.makeRequest<any>('get', `staff/center/${centerId}/info`);
  }

  public getGroups(): Observable<GroupList> {
    const outputObservable = new Subject<GroupList>();
    const user = this.sessionService.getUserLogin();
    this.userService.getGroupHierarchyByUserId(user.userId).subscribe((res) => {
      this.groupList = {};
      this.regionList = {};
      const allClinics = res[0];
      const allGroups = res.length > 1 ? res[1] : [];
      const groupPermissions = res[2];

      let tempAreaList: GroupList = {};

      // Create area list
      // Areas are Type 4, Level 5
      allClinics.forEach((clinic) => {
        const parentGroup = allGroups.find((g) => g.IdCenterGroup === clinic.IdCenterGroup);
        if (parentGroup && parentGroup?.IdCenterGroupType === 4 && parentGroup.HierarchyLevel === 5) {
          if (!tempAreaList[clinic.IdCenterGroup]) {
            tempAreaList[clinic.IdCenterGroup] = {
              Name: clinic.CenterGroupName,
              IdCenterGroup: clinic.IdCenterGroup,
              Parent_IdCenterGroup: parentGroup.Parent_IdCenterGroup,
              GroupLevel: 5,
              Clinics: [],
              Permission: groupPermissions.indexOf(clinic.IdCenterGroup) !== -1,
            };
          }
          tempAreaList[clinic.IdCenterGroup].Clinics.push(clinic);
        }
      });

      // Create region list
      // Regions are Type 2, Level 4
      allGroups.forEach((group) => {
        const parentGroup = allGroups.find((g) => g.IdCenterGroup === group.Parent_IdCenterGroup);
        if (
          parentGroup &&
          !this.groupList[group.IdCenterGroup] &&
          parentGroup.IdCenterGroupType === 2 &&
          parentGroup.HierarchyLevel === 4
        ) {
          this.groupList[parentGroup.IdCenterGroup] = {
            Name: parentGroup.CenterGroupName,
            IdCenterGroup: parentGroup.IdCenterGroup,
            Parent_IdCenterGroup: parentGroup.Parent_IdCenterGroup,
            GroupLevel: 4,
            Groups: Object.values(tempAreaList).filter(
              (area) => area.Parent_IdCenterGroup === parentGroup.IdCenterGroup,
            ),
            Permission: groupPermissions.indexOf(parentGroup.IdCenterGroup) !== -1,
          };
        }
      });

      outputObservable.next(this.groupList);
      outputObservable.complete();
    });

    return outputObservable.asObservable();
  }

  public getArea(idCenterGroup: number) {
    for (let region of Object.values(this.groupList)) {
      const area = region.Groups.find((area) => area.IdCenterGroup === idCenterGroup && area.GroupLevel === 5);
      if (area) return area;
    }
  }
  public getRegion(idCenterGroup: number) {
    return Object.values(this.groupList).find(
      (group) => group.IdCenterGroup === idCenterGroup && group.GroupLevel === 4,
    );
  }
  public getFirstArea() {
    return Object.values(this.groupList).find((group) => group.Groups.length).Groups[0];
  }

  /**
   * Assign staff on multiple shifts
   * @param providerId - unique identification for selected staff (providerId = intStaff_Id)
   * @param selectedShifts - list of selected shifts for the selected two week interval
   */
  public setShiftsForStaff(
    providerId: number,
    selectedShifts: SelectedShifts[],
    staffConfigEvents: IStaffConfigEvents[],
  ) {
    const { userId = null } = this.sessionService.getUserLogin();
    if (!providerId || !selectedShifts || !userId) return;

    const payload = {
      userId,
      selectedShifts,
      staffConfigEvents,
    };

    return this.api.makeRequest<IUpdateShift>('post', `staff/${providerId}/shifts`, { payload });
  }

  public updateShift(shiftInfo: ShiftModalInfo, legacyCenterId: number) {
    const { userId = null } = this.sessionService.getUserLogin();
    if (!legacyCenterId || !shiftInfo || !userId)
      return throwError(
        () =>
          new Error(`LegacyCenterId: ${legacyCenterId},  ShiftInfo: ${shiftInfo},  UserId: ${userId} must not be null`),
      );

    const payload = {
      userId,
      isSchedule: shiftInfo.isSchedule,
      shiftDate: shiftInfo.shiftDate,
      breakTime: shiftInfo.breakTime,
      isSplit: shiftInfo.isSplit,
      note: shiftInfo.notes || '',
      idProvider: shiftInfo.staffId || null,
      idPod: (shiftInfo.pod && shiftInfo.pod.podId) || null,
      shiftType: (shiftInfo.shiftType && shiftInfo.shiftType.lookupCode) || null,
      startTime: shiftInfo.allDayChecked ? '00:00' : shiftInfo.startTime,
      endTime: shiftInfo.allDayChecked ? '23:59' : shiftInfo.endTime,
      legacyCenterId,
    };

    return this.api.makeRequest<IUpdateShift>('post', `staff/shift/${shiftInfo.shiftId}/update-shift`, { payload });
  }

  public deleteShift(shiftInfo: ShiftModalInfo, centerId: number) {
    const { userId = null } = this.sessionService.getUserLogin();
    const { shiftId, isSchedule, shiftDate } = shiftInfo;
    if (!userId || !shiftId)
      return throwError(() => new Error(`UserId: ${userId} and ShiftId: ${shiftId} must not be null.`));

    const payload = {
      userId,
      isSchedule,
      shiftDate,
      centerId,
    };

    return this.api.makeRequest<IUpdateShift>('delete', `staff/shift/${shiftId}`, { payload });
  }

  public addShift(centerId: number, shiftInfo: ShiftModalInfo) {
    const { userId = null } = this.sessionService.getUserLogin();
    if (!centerId || !shiftInfo || !userId)
      return throwError(
        () => new Error(`CenterId: ${centerId},  ShiftInfo: ${shiftInfo},  UserId: ${userId} must not be null`),
      );

    const payload = {
      userId,
      idProvider: shiftInfo.staffId,
      shiftDate: shiftInfo.shiftDate,
      breakTime: shiftInfo.breakTime,
      note: shiftInfo.notes || '',
      providerRole: shiftInfo.shiftRoleCode,
      providerRoleType: shiftInfo.shiftRoleType,
      shiftType: (shiftInfo.shiftType && shiftInfo.shiftType.lookupCode) || null,
      idPod: (shiftInfo.pod && shiftInfo.pod.podId) || null,
      startTime: shiftInfo.allDayChecked ? '00:00' : shiftInfo.startTime,
      endTime: shiftInfo.allDayChecked ? '23:59' : shiftInfo.endTime,
    };

    return this.api.makeRequest<IUpdateShift>('post', `staff/center/${centerId}/add-shift`, { payload });
  }

  public exportStaffSchedule(centerId: string, startDate: string): Observable<any> {
    let queryParams = new HttpParams().append('date', startDate.toString());
    return this.api.makeRequest<any>('get', `export/staff/clinic/${centerId}`, { queryParams });
  }
}
