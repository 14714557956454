import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { MessageService } from 'primeng/api';
import { defer, firstValueFrom, throwError } from 'rxjs';
import { catchError, finalize, timeout as rxjsTimeout } from 'rxjs/operators';

import { environment } from './../../../environments/environment';
import { ProgressIndicatorService } from './progress-indicator.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  readonly htUrl = environment.htApiEndpoint;
  readonly authUrl = environment.authApiEndpoint;
  readonly baseUrl = environment.fmcServicesUrl;
  readonly swUrl = environment.swUri;

  constructor(
    private http: HttpClient,
    private progressIndicatorService: ProgressIndicatorService,
    private messageService: MessageService,
    private router: Router,
  ) {}

  public makeRequest<T>(
    verb: IVerbs,
    path: string,
    {
      payload = {},
      queryParams = new HttpParams(),
      baseUrl = this.baseUrl,
      showIndicator = true,
      loadingMessage = 'Loading',
      errorMessage = '',
      displayToast = true,
      ignoreErrors = false,
      observe = undefined,
      responseType = undefined,
      timeout = 60000,
    }: IRequestBody = {} as IRequestBody,
  ) {
    // this is because other wise it gets angry google possible other uses for it...
    const observeFaker = observe as 'body';
    const responseTypeFaker = responseType as 'json';

    return defer(() => {
      if (showIndicator || loadingMessage !== 'Loading') this.progressIndicatorService.show(loadingMessage);
      return this.http
        .request<T>(verb, `${baseUrl}/${path}`, {
          body: payload,
          withCredentials: true,
          params: queryParams,
          observe: observeFaker,
          responseType: responseTypeFaker,
        })
        .pipe(
          rxjsTimeout(timeout),
          catchError((error) => this.handleError(error, errorMessage, displayToast, ignoreErrors)),
          finalize(() => {
            if (showIndicator) this.progressIndicatorService.hide();
          }),
        );
    });
  }

  public handleError(error: HttpErrorResponse, message: string, displayToast: boolean = true, ignoreErrors = false) {
    // maintenance mode is on
    if (error?.status === 309) {
      this.router.navigateByUrl('/maintenance');
    }
    // auth error
    else if (!ignoreErrors && error?.status === 401) {
      this.messageService.add({
        severity: 'error',
        summary: `Auth Error`,
        detail: 'An authentication error occurred. Please refresh your page to fix it.',
      });
    }
    // show the error like normal
    else if (!ignoreErrors && displayToast) {
      this.messageService.add({
        severity: 'error',
        summary: `${error.status} Error`,
        detail: `${error.statusText}: ${
          message || error?.error?.message || error?.error?.error || error?.message || error?.error
        }`,
      });
    }
    return throwError(() => error || 'Server error');
  }

  public makeAuthRequest<T>(
    verb: IVerbs,
    path: string,
    {
      payload = {},
      queryParams = new HttpParams(),
      baseUrl = this.authUrl,
      showIndicator = true,
      loadingMessage = 'Loading',
      errorMessage = '',
      displayToast = true,
      ignoreErrors = true,
    }: IRequestBody = {} as IRequestBody,
  ) {
    return this.makeRequest<T>(verb, path, {
      payload,
      queryParams,
      baseUrl,
      showIndicator,
      loadingMessage,
      errorMessage,
      displayToast,
      ignoreErrors,
    });
  }

  public makeHtRequest<T>(
    verb: IVerbs,
    path: string,
    {
      payload = {},
      queryParams = new HttpParams(),
      baseUrl = this.htUrl,
      showIndicator = true,
      loadingMessage = 'Loading',
      errorMessage = '',
      displayToast = true,
      ignoreErrors = false,
    }: IRequestBody = {} as IRequestBody,
  ) {
    return this.makeRequest<T>(verb, path, {
      payload,
      queryParams,
      baseUrl,
      showIndicator,
      loadingMessage,
      errorMessage,
      displayToast,
      ignoreErrors,
    });
  }

  public makeColdFusionRequest<T>(
    verb: IVerbs,
    path: string,
    {
      payload = {},
      queryParams = new HttpParams(),
      baseUrl = this.swUrl,
      showIndicator = true,
      loadingMessage = 'Loading',
      errorMessage = '',
      displayToast = true,
      ignoreErrors = false,
    }: IRequestBody = {} as IRequestBody,
  ) {
    return this.makeRequest<T>(verb, path, {
      payload,
      queryParams,
      baseUrl,
      showIndicator,
      loadingMessage,
      errorMessage,
      displayToast,
      ignoreErrors,
    });
  }

  public async downloadFile(
    verb: IVerbs,
    path: string,
    {
      payload = {},
      queryParams = new HttpParams(),
      baseUrl = this.baseUrl,
      showIndicator = true,
      loadingMessage = 'Loading',
      errorMessage = '',
      displayToast = true,
      ignoreErrors = false,
      observe = undefined,
      responseType = undefined,
      timeout = 60000,
    }: IRequestBody = {} as IRequestBody,
  ) {
    const response = await firstValueFrom(
      this.makeRequest<IFileDownloadResponse>(verb, path, {
        payload,
        queryParams,
        baseUrl,
        showIndicator,
        loadingMessage,
        errorMessage,
        displayToast,
        ignoreErrors,
        observe,
        responseType,
        timeout,
      }),
    );

    const fetched = await fetch(`data:application/octet-stream;base64,${response.file}`);
    const blob = await fetched.blob();
    saveAs(blob, response.fileName);

    this.messageService.add({
      life: 5 * 1000, //5 seconds
      severity: 'success',
      summary: `${response.fileName} downloaded`,
      detail: 'You can find it in your browsers downloads folder.',
    });
  }
}

type IVerbList = 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH';
export type IVerbs = Uppercase<IVerbList> | Lowercase<IVerbList>;

export type IQueryParams =
  | HttpParams
  | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };

export interface IRequestBody {
  payload?: any;
  queryParams?: IQueryParams;
  baseUrl?: string;
  showIndicator?: boolean;
  loadingMessage?: string;
  errorMessage?: string;
  displayToast?: boolean;
  ignoreErrors?: boolean;
  observe?: string;
  responseType?: string;
  timeout?: number;
}

export interface IFileDownloadResponse {
  file: string;
  fileName: string;
}
