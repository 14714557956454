import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { LegendModalComponent } from 'src/app/schedule-wise/schedule-patient/components/legend-modal/legend-modal.component';
import { PatientsModalComponent } from 'src/app/schedule-wise/schedule-patient/components/patients-modal/patients-modal.component';
import { PhysiciansModalComponent } from 'src/app/schedule-wise/schedule-patient/components/physicians-modal/physicians-modal.component';
import { TransportationModalComponent } from 'src/app/schedule-wise/schedule-patient/components/transportation-modal/transportation-modal.component';
import { ClinicService } from 'src/app/shared/services/clinic.service';

@Component({
  standalone: true,
  selector: 'app-schedulewise-patient-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  imports: [DynamicDialogModule],
  providers: [DialogService],
})
export class ScheduleWisePatientSidebarComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  clinicId: number;
  constructor(public dialogService: DialogService, private clinicService: ClinicService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.clinicService.getClinic().subscribe({
        next: (clinic) => {
          this.clinicId = clinic.clinicId;
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  openPatients() {
    this.dialogService.open(PatientsModalComponent, {
      header: 'Patients',
      data: {
        clinicId: this.clinicId,
      },
    });
  }
  openTransportation() {
    this.dialogService.open(TransportationModalComponent, {
      header: 'Transportation',
      data: {
        clinicId: this.clinicId,
      },
    });
  }
  openPhysicians() {
    this.dialogService.open(PhysiciansModalComponent, {
      header: 'Physicians',
      data: {
        clinicId: this.clinicId,
      },
    });
  }
  openLegend() {
    this.dialogService.open(LegendModalComponent, {
      width: '50vw',
      header: 'Legend',
    });
  }
}
