import { Component, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DayNamesFull, SingleDayFrequencyInterval } from 'src/app/shared/enums/frequency-intervals';

import { IPatientsModalResponse } from '../../models/patient-modal';
import {
  createEmptyTreatment,
  DEFAULT_ACCUITIES,
  IPatientTreatmentEvent,
  putPatientInTreatment,
} from '../../models/schedule-patient.models';
import { PatientScheduleService } from '../../services/patient-schedule.service';
import { PatientsModalService } from '../patients-modal/patients-modal.service';

@Component({
  standalone: false,
  selector: 'app-shared-chair-edit-modal',
  templateUrl: './shared-chair-edit-modal.component.html',
  styleUrls: ['./shared-chair-edit-modal.component.scss'],
})
export class SharedChairEditModalComponent implements OnInit {
  public treatments: IPatientTreatmentEvent[][];
  public patients: IPatientsModalResponse[];
  public legacyIdCenter: number;
  public podName: string;
  public chairName: string;
  public shiftNumber: number;
  public dayNamesFull = DayNamesFull;
  private draggedPatient: IPatientsModalResponse;
  private dirty = false;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private patientsModalService: PatientsModalService,
    private patientScheduleService: PatientScheduleService,
  ) {}

  ngOnInit(): void {
    this.treatments = this.config.data.treatments;
    this.legacyIdCenter = this.config.data.legacyIdCenter;
    this.podName = this.config.data.podName;
    this.chairName = this.config.data.chairName;
    this.shiftNumber = this.config.data.shiftNumber;
    this.patientsModalService.getPatients(this.legacyIdCenter).subscribe((res) => {
      this.patients = res;
    });
  }

  public removeTreatment(tx: IPatientTreatmentEvent[]) {
    if (tx.length) {
      let treatment = tx[0];
      if (treatment.LastName && treatment.SharedChairDay) {
        this.patientScheduleService
          .closeTreatmentEvent(
            treatment.IdEventSchedule,
            treatment.ChairId,
            treatment.ShiftNumber,
            treatment.SharedChairDay - 1,
          )
          .subscribe(() => {
            this.dirty = true;
            tx.pop();
            tx.push(
              createEmptyTreatment(
                treatment.PodId,
                treatment.ChairId,
                treatment.ShiftNumber,
                treatment.SharedChairDay,
                treatment.IdEventSchedule,
                treatment.ClinicConfigId,
              ),
            );
            this.treatments = cloneDeep(this.treatments);
          });
      }
    }
  }

  public createTreatment(tx: IPatientTreatmentEvent[], patient: IPatientsModalResponse) {
    if (tx.length) {
      this.patientScheduleService
        .createTreatmentEvent(
          this.legacyIdCenter,
          tx[0].IdEventSchedule,
          tx[0].ChairId,
          tx[0].ShiftNumber,
          tx[0].StartTime || '00:00',
          patient.DurationInMinutes,
          patient.IdPatient,
          DEFAULT_ACCUITIES.AcuityOnPre,
          DEFAULT_ACCUITIES.AcuityOffPre,
          DEFAULT_ACCUITIES.AcuityOnPost,
          DEFAULT_ACCUITIES.AcuityOffPost,
          tx[0].SharedChairDay - 1,
        )
        .subscribe((res) => {
          this.dirty = true;
          const newTx = putPatientInTreatment(tx[0], patient);
          newTx.ScheduleEventId = res[0].IdScheduleEvent;
          newTx.AcuityOnPre = DEFAULT_ACCUITIES.AcuityOnPre;
          newTx.AcuityOffPre = DEFAULT_ACCUITIES.AcuityOffPre;
          newTx.AcuityOnPost = DEFAULT_ACCUITIES.AcuityOnPost;
          newTx.AcuityOffPost = DEFAULT_ACCUITIES.AcuityOffPost;
          newTx.FrequencyInterval = SingleDayFrequencyInterval[tx[0].SharedChairDay - 1];
          tx.pop();
          tx.push(newTx);
          this.treatments = cloneDeep(this.treatments);
          this.draggedPatient = null;
        });
    }
  }

  public drop(tx: IPatientTreatmentEvent[]) {
    const ptDays = this.draggedPatient?.WeekDays ? this.draggedPatient?.WeekDays.split(',') : [];
    if (tx.length && !tx[0].LastName && ptDays.length < 3) {
      this.createTreatment(tx, this.draggedPatient);
    } else {
      this.draggedPatient = null;
    }
  }
  public dragStart(patient): void {
    this.draggedPatient = patient;
  }
  public close() {
    this.ref.close(this.dirty);
  }
  public editTreatmentTime() {
    this.dirty = true;
  }
}
