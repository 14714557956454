import { FrequencyInterval } from 'src/app/shared/enums/frequency-intervals';

import { IPatientsModalResponse } from './patient-modal';

export const EXCESSIVE_SHIFT_LENGTH = 14.5 * 60; // 14.5 hours
export const LONG_TURNOVER_TIME = 75;
export const SHORT_TURNOVER_TIME = 15;
export const TREATMENT_CONFLICT_TIME = 0;

export const LONG_TURNOVER_CLASS = 'turnover-long';
export const SHORT_TURNOVER_CLASS = 'turnover-short';
export const TURNOVER_CONFLICT_CLASS = 'turnover-conflict';

export const DEFAULT_ACCUITIES = {
  AcuityOnPre: 10,
  AcuityOffPre: 5,
  AcuityOnPost: 0,
  AcuityOffPost: 15,
};

export interface IPatientTreatmentEvent {
  // Treatment data
  TreatmentEventId: number;
  ScheduleEventId: number;
  PodId?: number;
  ChairId: number;
  ShiftNumber: number;
  FrequencyInterval: FrequencyInterval;
  StartTime: string;
  Duration: number;
  AcuityOffPost: number;
  AcuityOffPre: number;
  AcuityOnPost: number;
  AcuityOnPre: number;
  PreTurnover?: number;
  PostTurnover?: number;

  // Patient data
  PatientId: number;
  IdPatientMaster: number;
  LegacyPatientId: number;
  MedicalRecordNumber: string;
  MiddleName: string;
  FirstName: string;
  LastName: string;
  PatientNeeds: string[];
  PatientType: number;

  // Disease status
  HepBStatus: string;
  Covid19Status: string;

  // Misc data
  IdEventSchedule?: number;
  ClinicConfigId?: number;
  ChairStatus: string;
  StatusReason: string;
  StatusReasonId: number;
  Note: string;

  PrevStartTime?: string;
  PrevChairId?: number;
  PrevPod?: string;
  PrevChair?: string;

  SharedChairDay?: number;
}

export function createEmptyTreatment(
  PodId: number,
  ChairId: number,
  ShiftNumber: number,
  SharedChairDay?: number,
  IdEventSchedule?: number,
  ClinicConfigId?: number,
): IPatientTreatmentEvent {
  return {
    TreatmentEventId: null,
    ScheduleEventId: null,
    PodId,
    ChairId,
    ShiftNumber,
    FrequencyInterval: null,
    StartTime: null,
    Duration: null,
    AcuityOffPost: null,
    AcuityOffPre: null,
    AcuityOnPost: null,
    AcuityOnPre: null,
    PatientId: null,
    IdPatientMaster: null,
    LegacyPatientId: null,
    MedicalRecordNumber: null,
    MiddleName: null,
    FirstName: null,
    LastName: null,
    PatientNeeds: [],
    PatientType: 1,
    HepBStatus: 'Unknown',
    Covid19Status: null,
    IdEventSchedule,
    ClinicConfigId,
    ChairStatus: '',
    StatusReason: null,
    StatusReasonId: null,
    Note: null,
    SharedChairDay,
  };
}

export function putPatientInTreatment(tx: IPatientTreatmentEvent, pt: IPatientsModalResponse): IPatientTreatmentEvent {
  return {
    TreatmentEventId: null,
    ScheduleEventId: null,
    PodId: tx.PodId,
    ChairId: tx.ChairId,
    ShiftNumber: tx.ShiftNumber,
    FrequencyInterval: null,
    StartTime: tx.StartTime || '00:00',
    Duration: pt.DurationInMinutes,
    AcuityOffPost: null,
    AcuityOffPre: null,
    AcuityOnPost: null,
    AcuityOnPre: null,
    PatientId: pt.Id,
    IdPatientMaster: null,
    LegacyPatientId: pt.IdPatient,
    MedicalRecordNumber: pt.MedicalRecordNumber,
    MiddleName: pt.PatientMiddleName,
    FirstName: pt.PatientFirstName,
    LastName: pt.PatientLastName,
    PatientNeeds: [],
    PatientType: 1,
    HepBStatus: pt.HepBStatus,
    Covid19Status: pt.Covid19Status,
    IdEventSchedule: tx.IdEventSchedule,
    ClinicConfigId: tx.ClinicConfigId,
    ChairStatus: tx.ChairStatus,
    StatusReason: tx.StatusReason,
    StatusReasonId: tx.StatusReasonId,
    Note: tx.Note,
    SharedChairDay: tx.SharedChairDay,
  };
}

export interface IStaffShiftEvent {
  Id: number;
  PodId: number;
  IdEventSchedule?: number;
  IdScheduleEvent?: number;
  ClinicConfigId?: number;
  StartTime: string;
  EndTime: string;
  Duration: number;
  Weekday: number;
  ProviderRole: string; // ex. 'PCT'
  ProviderType: string; // ex 'DPC'
  DateAdded: Date;
  SortOrder: number;

  PrevStartTime?: string;
  PrevEndTime?: string;
  NewShift?: boolean;
  OldShift?: boolean;
}

export interface IWizardHoursResponse {
  MasterPCTHours?: number;
  MasterRNHours?: number;
  SandboxPCTHours?: number;
  SandboxRNHours?: number;
  WizardMasterPCTHours?: number;
  WizardMasterRNHours?: number;
  WizardSandboxPCTHours?: number;
  WizardSandboxRNHours?: number;
}

export interface IStateRatio {
  idTAPRatioStates: number;
  stateCode: string;
  staffRoleCode: string;
  ratio: number;
  stateMandated: boolean;
}

export interface IScheduleStaffShift {
  idScheduleEvent: number;
  startTime: string;
  endTime: string;
}
