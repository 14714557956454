<div class="shared-chair-modal">
  <div class="shared-chair-header">
    <b>{{ podName }} - Chair {{ chairName }} - Shift {{ shiftNumber }}</b>
    <div class="instruction-text">
      To create a shared chair on the patient schedule, click and drag the existing patients from the left column over
      to the desired day the patient needs.
    </div>
  </div>

  <div class="shared-chair-container">
    <div class="patient-list">
      <p-table styleClass="p-datatable-striped" [scrollable]="true" scrollHeight="22rem" [value]="patients">
        <ng-template pTemplate="header">
          <tr>
            <th>Name</th>
            <th>Days</th>
            <th>Duration</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-patient>
          <tr pDraggable="patients" (onDragStart)="dragStart(patient)" [ngClass]="['patient-row', patient.HepBStatus]">
            <td>{{ patient.PatientFirstName }} {{ patient.PatientLastName }}</td>
            <td>{{ patient.WeekDays }}</td>
            <td>{{ patient.DurationInMinutes }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div *ngIf="treatments.length" class="treatment-list">
      <div *ngFor="let tx of treatments" class="treatment">
        <div class="treatment-header">
          <div>{{ dayNamesFull[tx[0].SharedChairDay - 1] }}</div>
          <div class="remove-button" (click)="removeTreatment(tx)" *ngIf="tx[0].LastName">
            Remove
            <i class="pi pi-times-circle"></i>
          </div>
        </div>
        <div pDroppable="patients" (onDrop)="drop(tx)">
          <app-schedule-patient-treatment
            [treatments]="tx"
            [legacyIdCenter]="legacyIdCenter"
            [selectedSharedChair]="0"
            [wizardMode]="false"
            [compareMode]="false"
            [podName]="podName"
            [chairName]="chairName"
            [sharedChairEditMode]="true"
            (timeChangeEvent)="editTreatmentTime()"></app-schedule-patient-treatment>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="p-dialog-footer app-dialog-footer">
  <p-button label="Close" (onClick)="close()" />
</div>
