<div>
  <form class="flex justify-content-between" (ngSubmit)="addPhysician()" *ngIf="addPermission">
    <div>
      <div class="my-1">
        <label class="bigger" for="firstName">First Name</label>
      </div>
      <input [maxLength]="50" id="firstName" name="firstName" type="text" [(ngModel)]="firstName" />
    </div>
    <div class="ml-2">
      <div class="my-1">
        <label class="bigger" for="lastName">Last Name</label>
      </div>
      <input [maxLength]="50" id="lastName" name="lastName" type="text" [(ngModel)]="lastName" />
    </div>
    <button
      class="ml-2"
      [disabled]="firstName.length === 0 || lastName.length === 0"
      pButton
      type="submit"
      label="Add Physician"></button>
  </form>
  <p-table
    [value]="physicians"
    sortField="nvarPhysician_Lname"
    [tableStyle]="{ 'min-width': '1rem', width: '100%' }"
    responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
        <th class="white-space-nowrap bigger" pSortableColumn="nvarPhysician_Lname">
          Name
          <p-sortIcon field="nvarPhysician_Lname"></p-sortIcon>
        </th>
        <th class="text-right bigger">Delete</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-physician>
      <tr class="no-background">
        <td class="too-big">{{ physician.nvarPhysician_Lname }}, {{ physician.nvarPhysician_Fname }}</td>
        <td class="text-right">
          <button
            *ngIf="deletePermission"
            pButton
            pRipple
            (click)="confirmDelete($event, physician)"
            type="button"
            icon="pi pi-times"
            class="p-button-rounded p-button-danger"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<!-- the popup where it will show otherwise it will appear in a modal -->
<p-confirmPopup></p-confirmPopup>
