import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from 'luxon';

@Pipe({
  standalone: true,
  name: 'minutesToHours',
})
export class MinutesToHoursPipe implements PipeTransform {
  transform(input: number): string {
    return minutesToHours(input);
  }
}

export function minutesToHours(input: number): string {
  return Duration.fromObject({ minutes: input }).toFormat("h'h' m'm'");
}
