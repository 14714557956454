<form
  (ngSubmit)="submit()"
  #patientForm="ngForm"
  class="form-width"
  [ngClass]="{ 'table-exists': patientInfo?.shifts?.length > 0 }">
  <div class="flex" *ngIf="patientInfo">
    <div class="grid">
      <div class="col-4">
        <div>Patient Name</div>
        <div>
          {{ patientInfo.patient.PatientLastName }}, {{ patientInfo.patient.PatientFirstName }}
          {{ patientInfo.patient.PatientMiddleName }}
        </div>
      </div>
      <div class="col-4">
        <div>Medical Record Number</div>
        <div>{{ patientInfo.patient.MedicalRecordNumber }}</div>
      </div>
      <div class="col-4">
        <div>Date of Birth</div>
        <div>{{ patientInfo.patient.birthDateJs | date : 'shortDate' }}</div>
      </div>
      <div class="col-4 flex flex-column">
        <label for="hep">Hepatitis Status</label>
        <p-dropdown
          [required]="true"
          inputId="hep"
          [options]="hepOptions"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="patientInfo.patient.HepBStatusId"
          [disabled]="patientInfo.patient.IsEcubeHepStatus"
          placeholder="Choose one"
          appendTo="body"></p-dropdown>
      </div>
      <div class="col-4 flex flex-column">
        <label for="physician">Physician</label>
        <p-dropdown
          appendTo="body"
          inputId="physician"
          [options]="physicianOptions"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="patientInfo.patient.Physician_IdProvider"
          placeholder="Choose one"></p-dropdown>
      </div>
      <div class="col-4 flex flex-column">
        <label for="startDate">Start Date</label>
        <p-calendar
          [required]="true"
          inputId="startDate"
          appendTo="body"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="patientInfo.patient.startDateJs"></p-calendar>
      </div>
      <div class="col-4 flex flex-column">
        <label for="transportation">Transportation</label>
        <p-dropdown
          appendTo="body"
          inputId="transportation"
          [options]="transportationOptions"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="patientInfo.patient.IdTransportation"
          placeholder="Choose one"></p-dropdown>
      </div>
      <div class="col-7 flex flex-column">
        <label for="constraintNote">Schedule Constraint Note</label>
        <input
          type="text"
          pInputText
          name="constraintNote"
          [maxlength]="50"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="patientInfo.patient.ScheduleConstraintNote"
          id="constraintNote" />
      </div>
      <div class="col-11 flex flex-column">
        <label for="generalNotes">General Notes</label>
        <textarea
          type="text"
          pInputTextarea
          [autoResize]="true"
          [rows]="3"
          name="generalNotes"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="patientInfo.patient.PatientNote"
          id="generalNotes"></textarea>
      </div>
    </div>
    <div class="ml-5 flex flex-column">
      <div>Additional Needs</div>
      <div class="field-checkbox">
        <p-checkbox
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="patientInfo.patient.AVFistula"
          [binary]="true"
          inputId="fistula"></p-checkbox>

        <label for="fistula">
          <span class="custom-icon icon-av-fistula"></span>
          AV Fistula
        </label>
      </div>
      <div class="field-checkbox">
        <p-checkbox
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="patientInfo.patient.AVGraft"
          [binary]="true"
          inputId="graft"></p-checkbox>

        <label for="graft">
          <span class="custom-icon icon-graft"></span>
          AV Graft
        </label>
      </div>
      <div class="field-checkbox">
        <p-checkbox
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="patientInfo.patient.Anchor"
          [binary]="true"
          inputId="anchor"></p-checkbox>
        <label for="anchor">
          <span class="custom-icon icon-anchor"></span>
          Anchor
        </label>
      </div>
      <div class="field-checkbox">
        <p-checkbox
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="patientInfo.patient.Assist"
          [binary]="true"
          inputId="assist"></p-checkbox>
        <label for="assist">
          <span class="custom-icon icon-assist"></span>
          Assist
        </label>
      </div>
      <div class="field-checkbox">
        <p-checkbox
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="patientInfo.patient.Catheter"
          [binary]="true"
          inputId="catheter"></p-checkbox>
        <label for="catheter">
          <span class="custom-icon icon-catheter"></span>
          Catheter
        </label>
      </div>
      <div class="field-checkbox">
        <p-checkbox
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="patientInfo.patient.HoyerLift"
          [binary]="true"
          inputId="hoyer"></p-checkbox>
        <label for="hoyer">
          <span class="custom-icon icon-hoyer-lift"></span>
          Hoyer Lift
        </label>
      </div>
      <div class="field-checkbox">
        <p-checkbox
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="patientInfo.patient.NewPatient"
          [binary]="true"
          inputId="newPatient"></p-checkbox>
        <label for="newPatient">
          <span class="custom-icon icon-new-patient"></span>
          New Patient
        </label>
      </div>
      <div class="field-checkbox">
        <p-checkbox
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="patientInfo.patient.Integrated"
          [binary]="true"
          inputId="integratedCare"></p-checkbox>
        <label for="integratedCare">
          <span class="custom-icon icon-integrated-care"></span>
          Integrated Care
        </label>
      </div>
      <div class="field-checkbox">
        <p-checkbox
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="patientInfo.patient.Transient"
          [binary]="true"
          inputId="transient"></p-checkbox>
        <label for="transient">
          <span class="custom-icon icon-transient"></span>
          Transient
        </label>
      </div>
      <div class="field-checkbox">
        <p-checkbox
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="patientInfo.patient.Wheelchair"
          [binary]="true"
          inputId="wheelchair"></p-checkbox>
        <label class="" for="wheelchair">
          <span class="custom-icon icon-wheelchair"></span>
          Wheelchair
        </label>
      </div>
    </div>
  </div>
  <p-table *ngIf="patientInfo?.shifts?.length > 0" [value]="patientInfo?.shifts">
    <ng-template pTemplate="header">
      <tr>
        <th>Days</th>
        <th>Pod</th>
        <th>Chair</th>
        <th>Shift</th>
        <th>
          Ontime
          <br />
          (hr:min)
        </th>
        <th>Duration</th>
        <th>Offtime</th>
        <th>
          Acuity On
          <br />
          (pre/post)
        </th>
        <th>
          Acuity Off
          <br />
          (pre/post)
        </th>
        <th>Floor Schedule</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-shift let-rowIndex="rowIndex">
      <tr class="no-background">
        <td>{{ shift.daysOfTheWeek }}</td>
        <td>{{ shift.PodName }}</td>
        <td>{{ shift.ChairName }}</td>
        <td>{{ shift.ScheduleShift }}</td>
        <td>
          <p-inputMask
            [required]="true"
            mask="99:9a"
            characterPattern="[05]"
            [(ngModel)]="shift.ontime"
            (onBlur)="updateOfftime(shift)"
            slotChar="0"
            [name]="rowIndex + 'ontime'"></p-inputMask>
        </td>
        <td>
          <p-dropdown
            [required]="true"
            [disabled]="shift.IsEcubeDuration === 1"
            appendTo="body"
            [options]="hourOptions"
            (onChange)="updateOfftime(shift)"
            [name]="rowIndex + 'hoursDuration'"
            [(ngModel)]="shift.hours"></p-dropdown>
          <p-dropdown
            [required]="true"
            [disabled]="shift.IsEcubeDuration === 1"
            class="ml-1"
            appendTo="body"
            [options]="minuteOptions"
            (onChange)="updateOfftime(shift)"
            [name]="rowIndex + 'minutesDuration'"
            [(ngModel)]="shift.minutes"></p-dropdown>
        </td>
        <td>{{ shift.offtime }}</td>
        <td>
          <input
            [required]="true"
            class="small-size"
            [step]="5"
            [min]="10"
            [(ngModel)]="shift.AcuityOnPre_Minutes"
            pTooltip="The minimum value allowed is 10, and must be a multiple of 5"
            [tooltipDisabled]="patientForm.controls?.[rowIndex + 'onPre']?.valid"
            tooltipEvent="focus"
            [name]="rowIndex + 'onPre'"
            appDivisibleByFive
            placeholder="pre"
            type="number" />
          <input
            [required]="true"
            class="ml-1 small-size"
            [step]="5"
            [min]="5"
            [(ngModel)]="shift.AcuityOnPost_Minutes"
            pTooltip="The minimum value allowed is 5, and must be a multiple of 5"
            [tooltipDisabled]="patientForm.controls?.[rowIndex + 'onPost']?.valid"
            tooltipEvent="focus"
            [name]="rowIndex + 'onPost'"
            appDivisibleByFive
            placeholder="post"
            type="number" />
        </td>
        <td>
          <input
            [required]="true"
            class="small-size"
            [step]="5"
            [min]="0"
            pTooltip="The minimum value allowed is 0, and must be a multiple of 5"
            [tooltipDisabled]="patientForm.controls?.[rowIndex + 'offPre']?.valid"
            tooltipEvent="focus"
            [(ngModel)]="shift.AcuityOffPre_Minutes"
            [name]="rowIndex + 'offPre'"
            appDivisibleByFive
            placeholder="pre"
            type="number" />
          <input
            [required]="true"
            class="ml-1 small-size"
            [step]="5"
            [min]="15"
            [(ngModel)]="shift.AcuityOffPost_Minutes"
            pTooltip="The minimum value allowed is 15, and must be a multiple of 5"
            [tooltipDisabled]="patientForm.controls?.[rowIndex + 'offPost']?.valid"
            tooltipEvent="focus"
            [name]="rowIndex + 'offPost'"
            appDivisibleByFive
            placeholder="post"
            type="number" />
        </td>
        <td><p-button (click)="memoShift = shift; memoOverlay.toggle($event)">Memo</p-button></td>
      </tr>
    </ng-template>
  </p-table>
  <div class="flex justify-content-end">
    <div>
      <button pButton type="button" (click)="close()" label="Close"></button>
      <button class="ml-2" [disabled]="patientForm.invalid" pButton type="submit" label="Save & Close"></button>
    </div>
  </div>
</form>

<p-overlayPanel #memoOverlay>
  <ng-template pTemplate>
    <div class="p-5">
      <textarea
        class="my-3 w-full"
        pInputTextarea
        name="memoText"
        id="memoText"
        [(ngModel)]="memoShift.ScheduleNote"
        [ngModelOptions]="{ standalone: true }"
        maxlength="25"></textarea>
      <div class="mb-2">{{ 25 - memoShift?.ScheduleNote?.length }} characters remaining</div>
    </div>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #minOverlay>
  <ng-template pTemplate>This must be greater than</ng-template>
</p-overlayPanel>

<p-overlayPanel #divisibleByFiveOverlay>
  <ng-template pTemplate>This must be divisible by five</ng-template>
</p-overlayPanel>
