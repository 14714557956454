import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TableModule } from 'primeng/table';
import { firstValueFrom, Subscription } from 'rxjs';
import { ClinicService } from 'src/app/shared/services/clinic.service';
import { OverwritePrimeNgService } from 'src/app/shared/services/primeNg.service';
import { SessionService } from 'src/app/shared/services/session.service';

import { ITransportationListResponse } from '../../models/patient-modal';
import { PatientsModalService } from '../patients-modal/patients-modal.service';

@Component({
  selector: 'app-transportation-modal',
  templateUrl: './transportation-modal.component.html',
  styleUrls: ['./transportation-modal.component.scss'],
  standalone: true,
  providers: [DialogService, ConfirmationService],
  imports: [CommonModule, FormsModule, ButtonModule, TableModule, ConfirmPopupModule],
})
export class TransportationModalComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[];
  private clinicId: number;
  private legacyIdClinic: number;
  public transportations: ITransportationListResponse[] = [];
  public addPermission: boolean = false;
  public deletePermission: boolean = false;
  public transportationName = '';

  constructor(
    private route: ActivatedRoute,
    private clinicService: ClinicService,
    private patientsModalService: PatientsModalService,
    private overwritePrimeNgService: OverwritePrimeNgService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private sessionService: SessionService,
    private config: DynamicDialogConfig,
  ) {}

  ngOnDestroy(): void {
    this.overwritePrimeNgService.resetPrimeNGTableColumnFilter();
    this.unsubscribe.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit(): void {
    this.addPermission = this.sessionService.checkUserPermissionsAny(['TRANSADD']);
    this.deletePermission = this.sessionService.checkUserPermissionsAny(['TRANSDEL']);

    this.overwritePrimeNgService.overridePrimeNGTableColumnFilter();

    this.unsubscribe = [
      this.route.paramMap.subscribe({
        next: async (params) => {
          if (params.get('clinicId')) {
            this.clinicId = +params.get('clinicId');
            this.legacyIdClinic = (
              await firstValueFrom(this.clinicService.getClinicInfo({ clinicId: this.clinicId }))
            ).legacyIdCenter;

            this.getTransportation();
          }
        },
      }),
    ];

    if (this.config?.data?.clinicId) {
      this.clinicId = this.config.data.clinicId;
      this.clinicService.getClinicInfo({ clinicId: this.clinicId }).subscribe((clinic) => {
        this.legacyIdClinic = clinic.legacyIdCenter;
        this.getTransportation();
      });
    }
  }

  sortTable(event: any) {
    const order = event.order;

    this.transportations.sort((a, b) => {
      // Always keep "Family" and "Self" at the top
      if (a.nvarTranspo_Name === 'Family') return -1;
      if (b.nvarTranspo_Name === 'Family') return 1;
      if (a.nvarTranspo_Name === 'Self') return -1;
      if (b.nvarTranspo_Name === 'Self') return 1;

      return order * a.nvarTranspo_Name.localeCompare(b.nvarTranspo_Name);
    });
  }

  getTransportation() {
    this.patientsModalService.getTransportation(this.legacyIdClinic).subscribe((transportations) => {
      this.transportations = transportations;
    });
  }

  confirmDelete(event: Event, transportation: ITransportationListResponse) {
    this.confirmationService.confirm({
      target: event.target,
      message: `Are you sure you want to delete ${transportation.nvarTranspo_Name}?`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: async () => {
        await firstValueFrom(this.patientsModalService.deleteTransportation(transportation.intTranspo_Id));
        this.messageService.add({
          severity: 'success',
          detail: `Transportation ${transportation.nvarTranspo_Name} deleted.`,
        });
        this.getTransportation();
      },
    });
  }

  addTransportation() {
    this.patientsModalService.addTransportation(this.legacyIdClinic, this.transportationName).subscribe(() => {
      this.transportationName = '';
      this.getTransportation();
    });
  }
}
