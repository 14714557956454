<div class="container">
  <div class="grid">
    <div class="col-8">
      <div class="grid border-bottom">
        <div class="col-12">
          <h2>Patient Info</h2>
          <img src="/client/assets/img/chair_info.svg" class="patient-info-img" />
        </div>
      </div>
      <div class="grid border-bottom">
        <div class="col-12 ontime">
          <div class="grid">
            <div class="col-6">
              <h2>Acuity On - Default Values</h2>
              <p>Acuity On Pre = 10 minutes</p>
              <p>Acuity On Post = 5 minutes</p>
            </div>
            <div class="col-6">
              <img src="/client/assets/img/ontime.svg" />
            </div>
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="col-12 offtime">
          <div class="grid">
            <div class="col-6">
              <h2>Acuity Off - Default Values</h2>
              <p>Acuity Off Pre = 0 minutes</p>
              <p>Acuity Off Post = 15 minutes</p>
            </div>
            <div class="col-6">
              <img src="/client/assets/img/offtime.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4 pl-2 border-left flex flex-column">
      <h2 class="needs pb-4">Additional Needs Icons</h2>
      <ul class="needs-list flex flex-column justify-content-between flex-1">
        <li>
          <i class="custom-icon icon-av-fistula"></i>
          AV Fistula
        </li>
        <li>
          <i class="custom-icon icon-graft"></i>
          AV Graft
        </li>
        <li>
          <i class="custom-icon icon-anchor"></i>
          Anchor
        </li>
        <li>
          <i class="custom-icon icon-assist"></i>
          Assist
        </li>
        <li>
          <i class="custom-icon icon-catheter"></i>
          Catheter
        </li>
        <li>
          <i class="fa-solid fa-biohazard"></i>
          Hepatitis Positive
        </li>
        <li>
          <i class="custom-icon icon-hoyer-lift"></i>
          Hoyer Lift
        </li>
        <li>
          <i class="custom-icon icon-integrated-care"></i>
          Integrated Care
        </li>
        <li>
          <i class="custom-icon icon-new-patient"></i>
          New Patient
        </li>
        <li>
          <i class="custom-icon icon-transient"></i>
          Transient
        </li>
        <li>
          <i class="custom-icon icon-wheelchair"></i>
          Wheelchair
        </li>
      </ul>
    </div>
  </div>
  <div class="footer pt-2 flex justify-content-end">
    <button pButton type="button" class="btn btn-primary" (click)="dynamicDialogRef.close()">Close</button>
  </div>
</div>
