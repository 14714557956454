import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment';
import { MenuItem, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PatientsEditModalComponent } from 'src/app/schedule-wise/schedule-patient/components/patients-edit-modal/patients-edit-modal.component';
import { DayLetters, FrequencyIntervalDays } from 'src/app/shared/enums/frequency-intervals';

import { IPatientShiftEvent } from '../../models/patient-modal';
import {
  IPatientTreatmentEvent,
  LONG_TURNOVER_CLASS,
  LONG_TURNOVER_TIME,
  SHORT_TURNOVER_CLASS,
  SHORT_TURNOVER_TIME,
  TREATMENT_CONFLICT_TIME,
  TURNOVER_CONFLICT_CLASS,
} from '../../models/schedule-patient.models';
import { PatientsModalService } from '../patients-modal/patients-modal.service';
import { SharedChairEditModalComponent } from '../shared-chair-edit-modal/shared-chair-edit-modal.component';

@Component({
  selector: 'app-schedule-patient-treatment',
  templateUrl: './schedule-patient-treatment.component.html',
  styleUrls: ['./schedule-patient-treatment.component.scss'],
  providers: [DialogService],
})
export class SchedulePatientTreatmentComponent implements OnChanges {
  @Input() treatments: IPatientTreatmentEvent[];
  @Input() legacyIdCenter: number;
  @Input() wizardMode: boolean;
  @Input() compareMode: boolean;
  @Input() selectedSharedChair: number = 0;
  @Input() podName: string;
  @Input() chairName: string;
  @Input() sharedChairEditMode = false;
  @Output() selectedSharedChairChange: EventEmitter<number> = new EventEmitter();
  @Output() timeChangeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() refreshData: EventEmitter<boolean> = new EventEmitter();
  public treatment: IPatientTreatmentEvent;
  public durationDisplay = '';
  public startTimeField = '';
  public closed = false;
  public open = false;
  public empty = false;
  public transient = false;
  public emptySharedChairSlot = false;
  public missingTreatment = false;
  public preTurnoverClass = '';
  public postTurnoverClass = '';
  public preTurnoverMessage = '';
  public postTurnoverMessage = '';
  public startTime = '--:--';
  public endTime = '--:--';
  public menuItems: MenuItem[];
  public popoverVisible = false;
  public showCompare = false;
  public compareDisplay = '';
  public invalidStart = false;
  public dayLetters = DayLetters;
  private initialStartTime = '--:--';
  private editSharedChairModal: DynamicDialogRef;

  constructor(
    public dialogService: DialogService,
    private patientsModalService: PatientsModalService,
    private ms: MessageService,
  ) {}

  ngOnChanges(changes?: {
    treatments?: IPatientTreatmentEvent;
    compareMode?: boolean;
    selectedSharedChair?: number;
  }): void {
    this.treatment = this.treatments[this.selectedSharedChair] || this.treatments[0] || null;
    if (this.treatment) {
      this.updateTimeDisplay();
      this.updateTime();
      this.updateMenuItems();
      this.calculateTurnoverIssues();
    } else {
      this.missingTreatment = true;
    }
    if (changes?.compareMode) {
      if (this.treatment) {
        this.showCompare = this.compareMode && (!!this.treatment.PrevChair || !!this.treatment.PrevStartTime);
      }
    }
  }

  public openEditPatients(): void {
    this.dialogService.open(PatientsEditModalComponent, {
      header: 'Patients',
      data: {
        patientId: this.treatment.PatientId,
        legacyIdClinic: this.legacyIdCenter,
      },
    });
  }

  public openEditSharedChair(): void {
    let modalTreatments = this.treatments;
    if (this.treatments.length === 1) {
      modalTreatments = [cloneDeep(this.treatments[0]), cloneDeep(this.treatments[0]), cloneDeep(this.treatments[0])];
      for (let i = 0; i < modalTreatments.length; i++) {
        modalTreatments[i].SharedChairDay = FrequencyIntervalDays[modalTreatments[i].FrequencyInterval][i];
      }
    }
    this.editSharedChairModal = this.dialogService.open(SharedChairEditModalComponent, {
      header: 'Edit Shared Chair',
      width: '50rem',
      closable: false,
      data: {
        treatments: modalTreatments.map((t) => {
          return [t];
        }),
        legacyIdCenter: this.legacyIdCenter,
        podName: this.podName,
        chairName: this.chairName,
        shiftNumber: this.treatment.ShiftNumber,
      },
    });
    this.editSharedChairModal.onClose.subscribe((res) => {
      if (res) this.refreshData.emit(true);
    });
  }

  public showPopover(): void {
    if (!this.sharedChairEditMode && !this.wizardMode) this.popoverVisible = true;
  }
  public hidePopover(): void {
    this.popoverVisible = false;
  }

  public onStartTimeChange(): void {
    const startTimeDetails: string[] = this.startTime.split(':');
    if (
      !isNaN(+startTimeDetails[0]) &&
      !isNaN(+startTimeDetails[1]) &&
      +startTimeDetails[0] <= 23 &&
      +startTimeDetails[1] <= 59
    ) {
      this.invalidStart = false;
      if (this.initialStartTime !== this.startTime) {
        const patientShiftEvent: IPatientShiftEvent = {
          idScheduleEvent: this.treatment.ScheduleEventId,
          startTime: this.startTime,
          durationInMinutes: this.treatment.Duration,
          acuityOnPreMinutes: this.treatment.AcuityOnPre,
          acuityOffPreMinutes: this.treatment.AcuityOffPre,
          acuityOnPostMinutes: this.treatment.AcuityOnPost,
          acuityOffPostMinutes: this.treatment.AcuityOffPost,
        };

        this.patientsModalService.updatePatientSchedule(patientShiftEvent).subscribe({
          next: () => {
            this.updateTime();
            this.timeChangeEvent.emit(true);
            this.ms.add({ severity: 'success', detail: 'This shift has been successfully updated.' });
          },
          error: (err) => {
            this.ms.add({ severity: 'error', detail: err.errorMsg });
          },
        });
      }
    } else {
      this.invalidStart = true;
    }
  }

  public changeSharedChair(i: number): void {
    this.selectedSharedChair = i;
    this.treatment = this.treatments[this.selectedSharedChair] || this.treatments[0] || null;
    this.updateTimeDisplay();
    this.updateTime();
    this.updateMenuItems();
    this.calculateTurnoverIssues();
    this.selectedSharedChairChange.emit(i);
  }

  private updateTime(): void {
    this.initialStartTime = this.startTime;
    const newStartTime = moment.utc(this.treatment.StartTime);
    const newEndTime = moment.utc(this.treatment.StartTime);
    const startTimeDetails: string[] = this.startTime.split(':');
    newStartTime.set({ hour: +startTimeDetails[0], minute: +startTimeDetails[1], second: 0, millisecond: 0 });
    newEndTime.set({ hour: +startTimeDetails[0], minute: +startTimeDetails[1], second: 0, millisecond: 0 });
    this.endTime = this.treatment.StartTime
      ? newEndTime.add(this.treatment.Duration, 'minutes').format('HH:mm')
      : '--:--';
    this.treatment.StartTime = newStartTime.toISOString();
  }

  private calculateTurnoverIssues(): void {
    this.preTurnoverClass = '';
    if (this.treatment.PreTurnover !== undefined) {
      this.preTurnoverClass =
        this.treatment.PreTurnover >= LONG_TURNOVER_TIME
          ? LONG_TURNOVER_CLASS
          : this.treatment.PreTurnover <= TREATMENT_CONFLICT_TIME
          ? TURNOVER_CONFLICT_CLASS
          : this.treatment.PreTurnover <= SHORT_TURNOVER_TIME
          ? SHORT_TURNOVER_CLASS
          : '';
    }
    this.postTurnoverClass = '';
    if (this.treatment.PostTurnover !== undefined) {
      this.postTurnoverClass =
        this.treatment.PostTurnover >= LONG_TURNOVER_TIME
          ? LONG_TURNOVER_CLASS
          : this.treatment.PostTurnover <= TREATMENT_CONFLICT_TIME
          ? TURNOVER_CONFLICT_CLASS
          : this.treatment.PostTurnover <= SHORT_TURNOVER_TIME
          ? SHORT_TURNOVER_CLASS
          : '';
    }
    this.preTurnoverMessage =
      this.preTurnoverClass === LONG_TURNOVER_CLASS
        ? 'LONG TURNOVER WITH PREVIOUS SHIFT'
        : this.preTurnoverClass === TURNOVER_CONFLICT_CLASS
        ? 'CONFLICT WITH PREVIOUS SHIFT'
        : this.preTurnoverClass === SHORT_TURNOVER_CLASS
        ? 'SHORT TURNOVER WITH PREVIOUS SHIFT'
        : '';
    this.postTurnoverMessage =
      this.postTurnoverClass === LONG_TURNOVER_CLASS
        ? 'LONG TURNOVER WITH NEXT SHIFT'
        : this.postTurnoverClass === TURNOVER_CONFLICT_CLASS
        ? 'CONFLICT WITH NEXT SHIFT'
        : this.postTurnoverClass === SHORT_TURNOVER_CLASS
        ? 'SHORT TURNOVER WITH NEXT SHIFT'
        : '';
  }

  private updateTimeDisplay(): void {
    this.durationDisplay = `${Math.floor(this.treatment.Duration / 60)}:${(this.treatment.Duration % 60)
      .toString()
      .padStart(2, '0')}`;
    this.closed = this.treatment.ChairStatus?.toLowerCase().indexOf('unavail') >= 0;
    this.open = this.treatment.ChairStatus.toLowerCase().indexOf('unavail') < 0 && !this.treatment.LastName;
    this.empty = !this.treatment.LastName && !this.treatment.StartTime && this.open;
    this.transient = this.treatment.PatientNeeds.includes('Transient') || this.treatment.PatientType === 2;
    this.emptySharedChairSlot = !!this.treatment.SharedChairDay && (this.open || this.empty);

    if (this.treatment.PrevStartTime) {
      const prevStartTime = DateTime.fromJSDate(new Date(this.treatment.PrevStartTime));
      const prevEndTime = prevStartTime.plus({ minutes: this.treatment.Duration });

      this.compareDisplay = `OLD: ${prevStartTime.toFormat('HH:mm')}-${prevEndTime.toFormat('HH:mm')}`;
    }
    if (this.treatment.PrevPod && this.treatment.PrevChair) {
      const podChairCompare = `${this.treatment.PrevPod} - ${this.treatment.PrevChair}`;
      this.compareDisplay = this.compareDisplay
        ? `${this.compareDisplay}, ${podChairCompare}`
        : `PREV: ${podChairCompare}`;
    }
    this.startTime = this.treatment.StartTime ? moment.utc(this.treatment.StartTime).format('HH:mm') : '--:--';
  }

  private updateMenuItems(): void {
    if (this.closed) {
      this.menuItems = [
        {
          label: 'Edit Chair Availability',
          icon: 'pi pi-pencil',
        },
      ];
    } else if (this.emptySharedChairSlot) {
      this.menuItems = [
        {
          label: 'Edit Shared Chair',
          icon: 'pi pi-users',
          command: () => {
            this.openEditSharedChair();
          },
        },
      ];
    } else if (this.open || this.empty) {
      this.menuItems = [
        {
          label: 'Add New Patient',
          icon: 'pi pi-user-plus',
        },
        {
          label: 'Add Existing Patient',
          icon: 'pi pi-user',
        },
        {
          label: 'Edit Chair Availability',
          icon: 'pi pi-pencil',
        },
      ];
    } else {
      this.menuItems = [
        {
          label: 'Edit Patient',
          icon: 'pi pi-user-edit',
          command: () => {
            this.openEditPatients();
          },
        },
        {
          label: 'Remove Patient',
          icon: 'pi pi-user-minus',
        },
        ...(this.treatments.length > 1
          ? [
              {
                label: 'Edit Shared Chair',
                icon: 'pi pi-users',
                command: () => {
                  this.openEditSharedChair();
                },
              },
            ]
          : [
              {
                label: 'Create Shared Chair',
                icon: 'pi pi-users',
                command: () => {
                  this.openEditSharedChair();
                },
              },
            ]),

        {
          label: 'Edit Chair Availability',
          icon: 'pi pi-pencil',
        },
      ];
    }
  }
}
