import { Injectable } from '@angular/core';
import { ColumnFilterFormElement } from 'primeng/table';

// This is how you override primeNG it is very hacky, but neccessary sometimes...
@Injectable({
  providedIn: 'root',
})
export class OverwritePrimeNgService {
  constructor() {}
  private oldFunctions: any = {};

  overridePrimeNGTableColumnFilter() {
    this.oldFunctions.ColumnFilterFormElementonModelChange = ColumnFilterFormElement.prototype.onModelChange;

    // This makes it so that when you type in a filter box it will filter as you type not just when you press enter
    ColumnFilterFormElement.prototype.onModelChange = function (value) {
      this.filterConstraint.value = value;
      if (this.type || value === '') {
        this.dt._filter();
      }
    };
  }

  resetPrimeNGTableColumnFilter() {
    ColumnFilterFormElement.prototype.onModelChange = this.oldFunctions.ColumnFilterFormElementonModelChange;
  }
}
