<div>
  <form class="flex justify-content-between" (ngSubmit)="addTransportation()" *ngIf="addPermission">
    <div>
      <div class="my-1">
        <label class="bigger" for="firstName">Transportation Name</label>
      </div>
      <input [maxLength]="50" id="firstName" name="firstName" type="text" [(ngModel)]="transportationName" />
    </div>
    <button
      class="ml-2"
      [disabled]="transportationName.length === 0"
      pButton
      type="submit"
      label="Add Transportation"></button>
  </form>
  <p-table
    [value]="transportations"
    sortField="nvarTranspo_Name"
    [tableStyle]="{ 'min-width': '1rem', width: '100%' }"
    responsiveLayout="scroll"
    [customSort]="true"
    (sortFunction)="sortTable($event)">
    <ng-template pTemplate="header">
      <tr>
        <th class="white-space-nowrap bigger" pSortableColumn="nvarTranspo_Name">
          Name
          <p-sortIcon field="nvarTranspo_Name"></p-sortIcon>
        </th>
        <th class="text-right bigger">Delete</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-transportation>
      <tr class="no-background">
        <td class="too-big">{{ transportation.nvarTranspo_Name }}</td>
        <td class="text-right">
          <button
            *ngIf="
              deletePermission &&
              transportation.nvarTranspo_Name !== 'Family' &&
              transportation.nvarTranspo_Name !== 'Self'
            "
            pButton
            pRipple
            (click)="confirmDelete($event, transportation)"
            type="button"
            icon="pi pi-times"
            class="p-button-rounded p-button-danger p-button-sm"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<!-- the popup where it will show otherwise it will appear in a modal -->
<p-confirmPopup></p-confirmPopup>
