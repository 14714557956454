import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClinicDetail, GroupResDetail } from 'src/app/dashboard/shared/clinic.models';

import { environment } from '../../../environments/environment';
import { UserPrivilege, UserRole } from '../models/user.model';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  baseUrl: string;
  constructor(private api: ApiService, private http: HttpClient) {
    this.baseUrl = `${environment.fmcServicesUrl}/users`;
  }

  public getUserByUsername(username: string) {
    return this.api.makeRequest('get', `users/search/${username}`);
  }

  public getClinicsByUserId(userId: number) {
    return this.api.makeRequest('get', `users/clinics/${userId}`);
  }

  public getGroupsByUserId(userId: number) {
    return this.api.makeRequest<[ClinicDetail[], GroupResDetail[]]>('get', `users/groups/${userId}`);
  }

  public getGroupHierarchyByUserId(userId: number) {
    return this.api.makeRequest<[ClinicDetail[], GroupResDetail[], number[]]>(
      'get',
      `users/groups-hierarchy/${userId}`,
    );
  }

  public setUserClinicAccess(roleId: number, userId: number, values: any) {
    return this.api.makeRequest('post', `users/permissions/${userId}`, { payload: { roleId, values } });
  }

  public getUserPermissions(userId: number) {
    return this.api.makeRequest('get', `users/permissions/${userId}`);
  }

  public getUserRoles(): Observable<UserRole[]> {
    return this.api.makeRequest<UserRole[]>('get', 'users/roles');
  }

  public getUserPrivileges(): Observable<UserPrivilege[]> {
    return this.api.makeRequest<UserPrivilege[]>('get', 'users/privileges');
  }
}
