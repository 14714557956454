import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appDivisibleByFive]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DivisibleByFiveDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class DivisibleByFiveDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value % 5 !== 0) return { divisibleByFive: { value: control.value % 5 === 0 } };
  }
}
