import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  standalone: true,
  selector: 'app-legend-modal',
  templateUrl: './legend-modal.component.html',
  styleUrls: ['./legend-modal.component.scss'],
  imports: [ButtonModule],
})
export class LegendModalComponent {
  constructor(public dynamicDialogRef: DynamicDialogRef) {}
}
