import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ClinicConfigTypeId } from 'src/app/shared/enums/clinic-config';
import { FrequencyInterval } from 'src/app/shared/enums/frequency-intervals';
import { ApiService } from 'src/app/shared/services/api.service';

import { IPatientTreatmentEvent, IScheduleStaffShift, IStateRatio } from '../models/schedule-patient.models';

@Injectable({
  providedIn: 'root',
})
export class PatientScheduleService {
  constructor(private api: ApiService) {}

  private $scheduleUpdate = new Subject<void>();

  public watchScheduleUpdates() {
    return this.$scheduleUpdate.asObservable();
  }
  public UpdateSchedule() {
    return this.$scheduleUpdate.next();
  }

  getPatientSchedules(
    clinicId: number,
    idCenter: number,
    clinicConfigIds: number[],
    idEventSchedules: number[],
  ): Observable<IPatientTreatmentEvent[]> {
    let queryParams = new HttpParams().append('clinicId', clinicId).append('idCenter', idCenter);
    if (clinicConfigIds?.length) queryParams = queryParams.append('clinicConfigIds', clinicConfigIds.join(','));
    if (idEventSchedules?.length) queryParams = queryParams.append('idEventSchedules', idEventSchedules.join(','));
    return this.api.makeRequest('get', `patient-schedule/schedules/treatments`, {
      queryParams,
      ignoreErrors: true,
    });
  }
  getStaffShifts(
    clinicId: number,
    idCenter: number,
    clinicConfigIds: number[],
    idEventSchedules: number[],
  ): Observable<IPatientTreatmentEvent[]> {
    let queryParams = new HttpParams().append('clinicId', clinicId).append('idCenter', idCenter);
    if (clinicConfigIds?.length) queryParams = queryParams.append('clinicConfigIds', clinicConfigIds.join(','));
    if (idEventSchedules?.length) queryParams = queryParams.append('idEventSchedules', idEventSchedules.join(','));
    return this.api.makeRequest('get', `patient-schedule/schedules/patient-staff`, {
      queryParams,
      ignoreErrors: true,
    });
  }
  // startDate and endDate should be formatted as 'YYYY-MM-DD'
  // scheduleSourceType should be 5 (master) or 8 (sandbox)
  getWizardHours(clinicId: number, startDate: string, endDate: string, days: string): Observable<any> {
    return this.api.makeRequest(
      'get',
      `optimizer/wizard/hours/${clinicId}?startDate=${startDate}&endDate=${endDate}&days=${days}`,
      {
        ignoreErrors: true,
      },
    );
  }

  startWizard(
    idCenter: number,
    idEventSchedule: number,
    scheduleSourceType: ClinicConfigTypeId,
    frequencyInterval: FrequencyInterval,
    rnRatio: number,
    userId: number,
  ): Observable<any> {
    return this.api.makeRequest('post', `optimizer/wizard/${idCenter}`, {
      payload: {
        eventScheduleID: idEventSchedule,
        sourceSchedule: scheduleSourceType,
        frequencyInterval,
        rnRatio,
        userId,
      },
    });
  }

  cancelWizard(clinicId: number, userId: string): Observable<any> {
    return this.api.makeRequest('post', `optimizer/cancel-wizard/${clinicId}`, {
      payload: {
        userId,
      },
    });
  }

  getRatios(idCenter: number): Observable<IStateRatio[]> {
    return this.api.makeRequest('get', `patient-schedule/ratios/${idCenter}`);
  }

  public updateStaffScheduleShift(scheduleStaffShift: IScheduleStaffShift): Observable<void> {
    return this.api.makeRequest<void>('put', `patient-schedule/schedules/staff-shift`, {
      payload: {
        ...scheduleStaffShift,
      },
    });
  }

  public createTreatmentEvent(
    idCenter: number,
    idEventSchedule: number,
    idChair: number,
    scheduleShift: number,
    startTime: string,
    durationInMinutes: number,
    idPatient: number,
    acuityOnPre_Minutes: number,
    acuityOffPre_Minutes: number,
    acuityOnPost_Minutes: number,
    acuityOffPost_Minutes: number,
    idWeekDay?: number,
  ): Observable<any> {
    return this.api.makeRequest<void>('post', `patient-schedule/create-treatment`, {
      payload: {
        idCenter,
        idEventSchedule,
        idChair,
        scheduleShift,
        startTime,
        durationInMinutes,
        idPatient,
        acuityOnPre_Minutes,
        acuityOffPre_Minutes,
        acuityOnPost_Minutes,
        acuityOffPost_Minutes,
        idWeekDay,
      },
    });
  }

  public closeTreatmentEvent(
    idEventSchedule: number,
    idChair: number,
    scheduleShift: number,
    idWeekDay?: number,
  ): Observable<any> {
    return this.api.makeRequest<void>('post', `patient-schedule/close-treatment`, {
      payload: {
        idEventSchedule,
        idChair,
        scheduleShift,
        idWeekDay,
      },
    });
  }
}
